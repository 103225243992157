import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { infofacts } from 'src/constants/infofacts';

function shuffleArray(array: number[]): number[] {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

function getVisibleLength(html: string): number {
  const container = document.createElement('div');
  container.innerHTML = html;
  return container.textContent?.length || 0;
}

function truncateHTML(html: string, count: number): string {
  const container = document.createElement('div');
  container.innerHTML = html;
  let remaining = count;

  const processNode = (node: Node): Node | null => {
    if (remaining <= 0) return null;

    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent || "";
      if (text.length <= remaining) {
        remaining -= text.length;
        return node.cloneNode(true);
      } else {
        const truncatedText = text.slice(0, remaining);
        remaining = 0;
        return document.createTextNode(truncatedText);
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node.cloneNode(false) as HTMLElement;
      for (let child of Array.from(node.childNodes)) {
        if (remaining <= 0) break;
        const processedChild = processNode(child);
        if (processedChild) {
          element.appendChild(processedChild);
        }
      }
      return element;
    }
    return null;
  };

  const fragment = document.createDocumentFragment();
  for (let child of Array.from(container.childNodes)) {
    if (remaining <= 0) break;
    const processedChild = processNode(child);
    if (processedChild) {
      fragment.appendChild(processedChild);
    }
  }
  const temp = document.createElement('div');
  temp.appendChild(fragment);
  return temp.innerHTML;
}

export function useIsMobile(): boolean {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth < 768);
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);
  return isMobile;
}

export function DynamicInfofacts(): JSX.Element {
  const [sequence, setSequence] = useState<number[]>(shuffleArray(Array.from({ length: infofacts.length }, (_, i) => i)));
  const [currentOrderIndex, setCurrentOrderIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(0);
  const [typing, setTyping] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const currentPhrase = infofacts[sequence[currentOrderIndex]];
  const totalVisibleLength = getVisibleLength(currentPhrase);
  const truncatedHTML = truncateHTML(currentPhrase, visibleCount);

  useEffect(() => {
    let timeoutId: number;
    if (typing) {
      if (visibleCount < totalVisibleLength) {
        timeoutId = window.setTimeout(() => {
          setVisibleCount(visibleCount + 1);
        }, 10);
      } else {
        if (!isHovered) {
          timeoutId = window.setTimeout(() => {
            setTyping(false);
          }, 5000);
        }
      }
    } else {
      if (!isHovered) {
        timeoutId = window.setTimeout(() => {
          let nextOrderIndex = currentOrderIndex + 1;
          if (nextOrderIndex >= sequence.length) {
            setSequence(shuffleArray(Array.from({ length: infofacts.length }, (_, i) => i)));
            nextOrderIndex = 0;
          }
          setCurrentOrderIndex(nextOrderIndex);
          setVisibleCount(0);
          setTyping(true);
        }, 500);
      }
    }
    return () => window.clearTimeout(timeoutId);
  }, [visibleCount, typing, totalVisibleLength, currentOrderIndex, sequence, isHovered, currentPhrase]);

  // Добавляем динамическое изменение высоты контейнера с плавной анимацией
  const containerRef = useRef<HTMLDivElement>(null);
  const baseline = 20; // базовая высота в пикселях
  const [containerHeight, setContainerHeight] = useState<number | string>(baseline);

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.getBoundingClientRect();
      const newHeight = visibleCount === 0
        ? baseline
        : Math.max(containerRef.current.scrollHeight, baseline);
      setContainerHeight(newHeight);
    }
  }, [currentOrderIndex, truncatedHTML, visibleCount]);

  return (
    <div 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        ref={containerRef}
        style={{
          height: typeof containerHeight === 'number' ? `${containerHeight}px` : containerHeight,
          minHeight: `${baseline}px`,
          transition: 'height 0.5s ease',
          overflow: 'hidden'
        }}
        dangerouslySetInnerHTML={{ __html: truncatedHTML }}
      />
    </div>
  );
} 