import React from 'react';

import { Icon } from 'src/components/Common/Icon';

import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { Recommendations } from 'src/@types/report';

import styles from '../../../../renders/RenderDescriptionSection.module.scss';

interface ReportRecommendationsProps {
    recommendations: Recommendations;
    expandedKeys: string[];
    setExpandedKeys: (keys: string[]) => void;
}

export const ReportRecommendations: React.FC<ReportRecommendationsProps> = ({ recommendations, expandedKeys, setExpandedKeys }) => {
    const sections = getSections(recommendations);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', marginBottom: '60px' }}>
            {sections.map(({ id, title, items, iconSymbol }) => (
                <ReportDescriptionSection
                    key={id}
                    id={id}
                    title={title}
                    items={items}
                    iconSymbol={iconSymbol}
                    expandedKeys={expandedKeys}
                    setExpandedKeys={setExpandedKeys}
                />
            ))}
        </div>
    );
};

const getSections = (recommendations: Recommendations) => {
    return [
        {
            id: 'actionable-steps',
            title: 'Actionable Steps',
            items: recommendations.actionable_steps,
            iconSymbol: (
                <Icon
                    name="checklist"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(1px)' }}
                />
            ),
        },
        {
            id: 'product-improvements',
            title: 'Product Improvements',
            items: recommendations.product_improvements,
            iconSymbol: (
                <Icon
                    name="checkmarkSeal"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(2px)' }}
                />
            ),
        },
        {
            id: 'service-enhancements',
            title: 'Service Enhancements',
            items: recommendations.service_enhancements,
            iconSymbol: (
                <Icon
                    name="star"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)' }}
                />
            ),
        },
    ];
};
