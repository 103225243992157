import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { RecordItem, RecordResponse } from 'src/@types/jtbd';
import { AdminRecordItem } from 'src/@types/jtbd';
import { useAdmin } from 'src/contexts/AdminContext';
import { useReportNotificationContext } from 'src/layouts/ReportNotificationContext';
import jtbdService from 'src/services/jtbd.service';
import jtbdAdminService from 'src/services/jtbd_admin.service';

type ProjectId = string;
type NewProjectName = string;

type ProjectListContext = {
    isEdit: boolean;
    setIsEdit: (value: boolean) => void;
    isLoadingProjectList: boolean;
    activeProjects: RecordItem[];
    archivedProjects: RecordItem[];
    storeData: { sizeMb: number; total: number };
    addProjectToArchived: (projectId: string) => void;
    addProjectToActive: (projectId: string) => void;
    deleteProject: (projectId: string) => void;
    resetProjectsChanges: () => void;
    prepareToChangingName: (projectId: string, name: string) => void;
    saveChanges: () => void;
    fetchProjectList: () => void;
    isAdmin: boolean;
    adminProjects: AdminRecordItem[];
};

const ProjectListCtx = createContext<ProjectListContext>({
    isEdit: false,
    setIsEdit: () => {},
    isLoadingProjectList: false,
    activeProjects: [],
    archivedProjects: [],
    storeData: { sizeMb: 0, total: 0 },
    addProjectToArchived: () => {},
    addProjectToActive: () => {},
    deleteProject: () => {},
    resetProjectsChanges: () => {},
    prepareToChangingName: () => {},
    saveChanges: () => {},
    fetchProjectList: () => {},
    isAdmin: false,
    adminProjects: [],
});

export const ProjectListProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { socketEvent } = useReportNotificationContext();
    const { isAdmin } = useAdmin();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isLoadingProjectList, setIsLoadingProjectList] = useState<boolean>(false);
    const [activeProjects, setActiveProjects] = useState<RecordItem[]>([]);
    const [archivedProjects, setArchivedProjects] = useState<RecordItem[]>([]);
    const [storeData, setStoreData] = useState<{ sizeMb: number; total: number }>({ sizeMb: 0, total: 0 });
    const loadedProjects = useRef<RecordResponse>();
    const [adminProjects, setAdminProjects] = useState<AdminRecordItem[]>([]);

    const itemsToChangeNames = useMemo(() => new Map<ProjectId, NewProjectName>(), []);
    const itemsToArchive = useMemo(() => new Set<ProjectId>(), []);
    const itemsToDelete = useMemo(() => new Set<ProjectId>(), []);

    const fetchProjectList = useCallback(async () => {
        try {
            setIsLoadingProjectList(true);
            const [projectsResponse, adminProjectsResponse] = await Promise.all([
                jtbdService.getRecords(),
                isAdmin ? jtbdAdminService.getRecords() : null,
            ]);

            loadedProjects.current = projectsResponse;
            const [archived, active] = splitProjectsListByActiveStatus(projectsResponse.records);
            setActiveProjects(active);
            setArchivedProjects(archived);

            if (adminProjectsResponse) {
                setAdminProjects(adminProjectsResponse.records);
            }

            setStoreData({
                sizeMb: projectsResponse.remaining_size_mb,
                total: projectsResponse.total,
            });
        } catch (error) {
            console.error('Failed to get the list of projects', error);
        } finally {
            setIsLoadingProjectList(false);
        }
    }, [isAdmin]);

    const addProjectToArchived = useCallback(
        (projectId: string) => {
            const project = activeProjects.find(project => project.id === projectId);
            if (!project) return;
            setActiveProjects(prev => prev.filter(project => project.id !== projectId));
            setArchivedProjects(prev => [
                {
                    ...project,
                    is_archived: true,
                },
                ...prev,
            ]);
            itemsToArchive.add(projectId);
        },
        [activeProjects, itemsToArchive]
    );

    const addProjectToActive = useCallback(
        (projectId: string) => {
            const project = archivedProjects.find(project => project.id === projectId);
            if (!project) return;
            setArchivedProjects(prev => prev.filter(project => project.id !== projectId));
            setActiveProjects(prev => [
                {
                    ...project,
                    is_archived: false,
                },
                ...prev,
            ]);
            if (itemsToArchive.has(projectId)) {
                itemsToArchive.delete(projectId);
            } else {
                itemsToArchive.add(projectId);
            }
        },
        [archivedProjects, itemsToArchive]
    );

    const deleteProject = useCallback(
        (projectId: string) => {
            const project = archivedProjects.find(project => project.id === projectId);
            if (!project) return;
            setArchivedProjects(prev => prev.filter(project => project.id !== projectId));
            itemsToDelete.add(projectId);
        },
        [archivedProjects, itemsToDelete]
    );

    const resetProjectsChanges = useCallback(() => {
        const [archived, active] = splitProjectsListByActiveStatus(loadedProjects.current?.records || []);
        setActiveProjects(active);
        setArchivedProjects(archived);
        itemsToArchive.clear();
        itemsToDelete.clear();
        itemsToChangeNames.clear();
    }, [itemsToArchive, itemsToChangeNames, itemsToDelete]);

    const prepareToChangingName = useCallback(
        (projectId: string, name: string) => {
            itemsToChangeNames.set(projectId, name);
        },
        [itemsToChangeNames]
    );

    const saveChanges = useCallback(async () => {
        setIsLoadingProjectList(true);
        const changeNames = Array.from(itemsToChangeNames.entries()).map(([id, name]) => ({ id, name }));
        const forArchive = Array.from(itemsToArchive.values());
        const forDelete = Array.from(itemsToDelete.values());
        await jtbdService.updateRecords({
            change_names: changeNames,
            for_archive: forArchive,
            for_delete: forDelete,
        });
        itemsToArchive.clear();
        itemsToDelete.clear();
        itemsToChangeNames.clear();
        fetchProjectList();
    }, [fetchProjectList, itemsToArchive, itemsToChangeNames, itemsToDelete]);

    useEffect(() => {
        fetchProjectList();
    }, [fetchProjectList, socketEvent]);

    const values = useMemo(
        () => ({
            isEdit,
            setIsEdit,
            isLoadingProjectList,
            activeProjects,
            archivedProjects,
            storeData,
            addProjectToArchived,
            addProjectToActive,
            deleteProject,
            resetProjectsChanges,
            prepareToChangingName,
            saveChanges,
            fetchProjectList,
            isAdmin,
            adminProjects,
        }),
        [
            isEdit,
            isLoadingProjectList,
            activeProjects,
            archivedProjects,
            storeData,
            addProjectToArchived,
            addProjectToActive,
            deleteProject,
            resetProjectsChanges,
            prepareToChangingName,
            saveChanges,
            fetchProjectList,
            isAdmin,
            adminProjects,
        ]
    );

    return <ProjectListCtx.Provider value={values}>{children}</ProjectListCtx.Provider>;
};

export const useProjectListContext = (): ProjectListContext => {
    const context = useContext(ProjectListCtx);
    if (context === undefined) {
        throw new Error('useProjectListContext must be used within a ProjectListProvider');
    }
    return context;
};

function splitProjectsListByActiveStatus(projects: RecordItem[]): [RecordItem[], RecordItem[]] {
    return projects.reduce<[RecordItem[], RecordItem[]]>(
        ([archived, active], project) => {
            project.is_archived ? archived.push(project) : active.push(project);
            return [archived, active];
        },
        [[], []]
    );
}
