import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Segmented } from 'antd';

import { Icon } from 'src/components/Common/Icon';

import { ReactComponent as ReportSplitViewCloseIcon } from 'src/assets/images/report/report-icon-splitview-close.svg';
import { ReactComponent as ReportSplitViewIcon } from 'src/assets/images/report/report-icon-splitview.svg';

import { TranscriptExample } from '../../../../@types/report';
import { useIsMobile } from '../../../Common/DynamicInfo';
import Referral from '../../../Referal/Referral';
import { useIsSetupContextAvailable } from '../ReportSetupContext';
import { ReportJTBD, ReportSetup, ReportTranscript } from './Tabs';
import { ErrorTabContent } from './Tabs/ErrorTabContent';
import { LoadingContent } from './Tabs/LoadingContent';
import { ReportAssistant } from './Tabs/ReportAssistant/ReportAssistant';
import { ReportOverview } from './Tabs/ReportOverview/ReportOverview';
import { ReportStatus } from 'src/@types/enums';
import { DetailedReportResponse } from 'src/@types/jtbd';

import styles from './ReportTabs.module.scss';

interface ReportTabsProps {
    report: DetailedReportResponse;
    reportId?: string;
}

const ReportTabs: React.FC<ReportTabsProps> = ({ report, reportId }) => {
    const [searchParams] = useSearchParams();
    const isAdminView = searchParams.get('source') === 'admin';
    const isSetupContextAvailable = useIsSetupContextAvailable();
    const [highlightedMessageId, setHighlightedMessageId] = useState<number | undefined>(undefined);

    const [activeTab, setActiveTab] = useState<string>(getDefaultActiveTab(report));
    const [secondaryActiveTab, setSecondaryActiveTab] = useState<string>('transcript');
    const [isSplitView, setIsSplitView] = useState<boolean>(false);
    const [splitPosition, setSplitPosition] = useState<number>(50); // Начальное положение разделителя (50%)
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();

    const isSetupAvailable = isAdminView || (reportId !== undefined && isSetupContextAvailable && report.setup);
    const isAssistantAvailable =
        // reportId && report.transcripts && (report.profile || report.insights || report.jtbd || report.recommendations);
        reportId && report.transcripts && report.transcripts.length > 0;

    const isTranscriptOnly = !!(
        reportId &&
        report.transcripts &&
        !(report.profile || report.insights || report.jtbd || report.recommendations)
    );

    const handleSourceClick = (example: TranscriptExample, fromSecondaryPanel: boolean = false) => {
        if (fromSecondaryPanel) {
            // Если клик был в правой панели, устанавливаем транскрипт в левой
            setActiveTab('transcript');
        } else {
            // Если клик был в левой панели, устанавливаем транскрипт в правой
            setSecondaryActiveTab('transcript');

            // Если не в режиме разделенного экрана, включить его
            if (!isSplitView) {
                setIsSplitView(true);
            }
        }
        setHighlightedMessageId(example.id);
    };

    const activeItems = [
        isSetupAvailable && {
            key: 'setup', // 􀍟
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="gear" width={17} height={17} fill="currentColor" className={styles.setupIcon} />
                    <span>Setup</span>
                </div>
            ),
            children: <ReportSetup />,
        },
        report.transcripts &&
            report.transcripts.length > 0 && {
                key: 'transcript', // 􀌮
                label: (
                    <div className={styles.tabLabel}>
                        <Icon name="quoteBubble" width={16} height={16} fill="currentColor" className={styles.setupIcon} />
                        <span>Transcript</span>
                    </div>
                ),
                children: <ReportTranscript transcripts={report.transcripts} highlightedMessageId={highlightedMessageId} />,
            },
        report.profile &&
            report.insights &&
            report.recommendations && {
                key: 'profile', // объединённый таб Overview
                label: (
                    <div className={styles.tabLabel}>
                        <Icon name="bookPages" width={18} height={18} fill="currentColor" className={styles.setupIcon} />
                        <span>Report</span>
                    </div>
                ),
                children: (
                    <ReportOverview
                        profile={report.profile}
                        insights={report.insights}
                        recommendations={report.recommendations}
                        onSourceClick={example => handleSourceClick(example, false)}
                    />
                ),
            },
        report.jtbd && {
            key: 'jtbd', // 􀵫
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="textWord" width={16} height={16} fill="currentColor" className={styles.setupIcon} />
                    <span>JTBD</span>
                </div>
            ),
            children: <ReportJTBD jtbd={report.jtbd} onSourceClick={example => handleSourceClick(example, false)} />,
        },

        isAssistantAvailable && {
            key: 'assistant', // 􀆿
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="sparkles" width={20} height={20} fill="currentColor" className={styles.setupIcon} />
                    <span>AI Chat</span>
                </div>
            ),
            children: <ReportAssistant reportId={reportId} isTranscriptOnly={isTranscriptOnly} />,
        },
    ].filter(Boolean) as { key: string; label: React.ReactNode; children: React.ReactNode }[];

    const secondaryItems = [
        report.transcripts &&
            report.transcripts.length > 0 && {
                key: 'transcript', // 􀌮
                label: (
                    <div className={styles.tabLabel}>
                        <Icon name="quoteBubble" width={16} height={16} fill="currentColor" className={styles.setupIcon} />
                        <span>Transcript</span>
                    </div>
                ),
                children: <ReportTranscript transcripts={report.transcripts} highlightedMessageId={highlightedMessageId} />,
            },
        report.profile &&
            report.insights &&
            report.recommendations && {
                key: 'profile', // объединённый таб Overview
                label: (
                    <div className={styles.tabLabel}>
                        <Icon name="bookPages" width={18} height={18} fill="currentColor" className={styles.setupIcon} />
                        <span>Report</span>
                    </div>
                ),
                children: (
                    <ReportOverview
                        profile={report.profile}
                        insights={report.insights}
                        recommendations={report.recommendations}
                        onSourceClick={example => handleSourceClick(example, true)}
                    />
                ),
            },
        report.jtbd && {
            key: 'jtbd', // 􀵫
            label: (
                <div className={styles.tabLabel}>
                    <Icon name="textWord" width={16} height={16} fill="currentColor" className={styles.setupIcon} />
                    <span>JTBD</span>
                </div>
            ),
            children: <ReportJTBD jtbd={report.jtbd} onSourceClick={example => handleSourceClick(example, true)} />,
        },
    ].filter(Boolean) as { key: string; label: React.ReactNode; children: React.ReactNode }[];

    const options = activeItems.map(item => ({
        label: item.label,
        value: item.key,
    }));

    const secondaryOptions = secondaryItems.map(item => ({
        label: item.label,
        value: item.key,
    }));

    const activeItem = activeItems.find(item => item.key === activeTab);
    const secondaryActiveItem = secondaryItems.find(item => item.key === secondaryActiveTab);

    const toggleSplitView = () => {
        setIsSplitView(!isSplitView);
        if (activeTab === 'setup' || activeTab === 'assistant') {
            const availableTabs = secondaryItems.map(item => item.key);
            if (availableTabs.length > 0) {
                setSecondaryActiveTab(availableTabs[0]);
            }
        } else {
            // Установим вторичный таб отличным от основного, если возможно
            const availableTabs = secondaryItems.map(item => item.key).filter(key => key !== activeTab);
            if (availableTabs.length > 0) {
                setSecondaryActiveTab(availableTabs[0]);
            } else if (secondaryItems.length > 0) {
                setSecondaryActiveTab(secondaryItems[0].key);
            }
        }
    };

    const handleDragStart = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDrag = useCallback(
        (e: MouseEvent) => {
            if (!isDragging || !containerRef.current) return;

            const containerRect = containerRef.current.getBoundingClientRect();
            const containerWidth = containerRect.width;
            const mouseX = e.clientX - containerRect.left;

            const newPosition = Math.min(Math.max((mouseX / containerWidth) * 100, 35), 65);
            setSplitPosition(newPosition);
        },
        [isDragging]
    );

    const handleDragEnd = useCallback(() => {
        setIsDragging(false);
    }, []);

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleDrag);
            document.addEventListener('mouseup', handleDragEnd);
        } else {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', handleDragEnd);
        }

        return () => {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', handleDragEnd);
        };
    }, [isDragging, handleDrag, handleDragEnd]);

    // Добавляем функцию для сброса выделенного сообщения
    const resetHighlightedMessage = () => {
        setHighlightedMessageId(undefined);
    };

    if (!isAdminView && report.status === ReportStatus.Error) {
        return <ErrorTabContent />;
    }

    if (report.status === ReportStatus.InQueue || report.status === ReportStatus.InProgress) {
        return <LoadingContent />;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0 }}>
            <div className={styles.tabControlContainer}>
                <div className={styles.tabsRow}>
                    <div className={styles.tabsGroup}>
                        <Segmented
                            className={styles.segmentedOption}
                            options={options}
                            size="large"
                            value={activeTab}
                            onChange={value => {
                                setActiveTab(value as string);
                                resetHighlightedMessage(); // Сбрасываем выделенное сообщение при смене вкладки
                            }}
                        />
                    </div>

                    {isSplitView && (
                        <div className={styles.tabsGroup}>
                            <Segmented
                                className={styles.segmentedOption}
                                options={secondaryOptions}
                                size="large"
                                value={secondaryActiveTab}
                                onChange={value => {
                                    setSecondaryActiveTab(value as string);
                                    resetHighlightedMessage(); // Сбрасываем выделенное сообщение при смене вкладки
                                }}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.tabControlActions}>
                    {!isMobile && isAssistantAvailable && (
                        <button
                            className={`${styles.splitViewButton} ${isSplitView ? styles.active : ''}`}
                            onClick={toggleSplitView}
                            title="Split View"
                        >
                            {isSplitView ? <ReportSplitViewCloseIcon /> : <ReportSplitViewIcon />}
                        </button>
                    )}

                    {isAdminView && (
                        <div className={styles.adminModeContainer}>
                            {/*<text>{report.status}</text>*/}
                            <span className={styles.reportStatus}>admin</span>
                        </div>
                    )}
                </div>
            </div>
            <div ref={containerRef} className={`${styles.contentContainer} ${isSplitView ? styles.splitView : ''}`}>
                <div className={styles.primaryContent} style={isSplitView ? { flex: `0 0 ${splitPosition}%` } : undefined}>
                    {activeItem && activeItem.children}
                </div>

                {isSplitView && (
                    <>
                        <div className={`${styles.divider} ${isDragging ? styles.dragging : ''}`} onMouseDown={handleDragStart}></div>
                        <div className={styles.secondaryContent} style={{ flex: `0 0 ${100 - splitPosition}%` }}>
                            {secondaryActiveItem && (secondaryActiveItem.key === 'profile' || secondaryActiveItem.key === 'jtbd')
                                ? React.cloneElement(secondaryActiveItem.children as React.ReactElement, {
                                      onSourceClick: (example: TranscriptExample) => handleSourceClick(example, true),
                                  })
                                : secondaryActiveItem && secondaryActiveItem.children}
                        </div>
                    </>
                )}
            </div>
            {!isSplitView && (
                <div className={styles.referralContainer}>
                    <Referral />
                </div>
            )}
        </div>
    );
};

export default ReportTabs;

const getDefaultActiveTab = (report: DetailedReportResponse): string => {
    if (report.profile) return 'profile';
    if (report.transcripts && report.transcripts.length > 0) return 'transcript';
    return 'setup';
};
