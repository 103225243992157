import { Button, Flex, Input, Select } from 'antd';

import { AseedText } from 'src/components/AseedTypography/AseedText/AseedText';
import { AseedTitle } from 'src/components/AseedTypography/AseedTitle/AseedTitle';
import { Icon } from 'src/components/Common/Icon';
import Informer from 'src/components/Common/Informer';
import { useReportSetupContext } from 'src/components/Report/ReportWidget/ReportSetupContext';

import { SetupCheckBox } from '../../../../../Menu/renders/CheckBoxes';
import { TEXT_AREA_GOALS, TEXT_AREA_NOTES } from '../../../../helpers/Placeholders';
import { AUTOMATIC_DETECTION, SAME_AS_TRANSCRIPT, createLanguageOptions, createParticipantOptions } from '../../../../helpers/SetupOptions';
import ReportGenerateMenu from '../../../ReportMenu/ReportGenerateMenu';
import { useReportWidgetContext } from '../../../ReportWidgetContext';
import { Language } from 'src/services/enums';

import tabStyles from '../../ReportTabs.module.scss';
import styles from './ReportSetup.module.scss';

const { respondentWrapper, deleteBtn } = styles;

const MAX_PARTICIPANTS = 6;
const MAX_RESPONDENTS = 3;

const RespondentInput: React.FC<{
    maxRespondents: number;
    respondents: string[];
    onRespondentsChange: (respondents: string[]) => void;
}> = ({ maxRespondents, respondents, onRespondentsChange }) => {
    const handleInputChange = (index: number, value: string) => {
        const newRespondents = [...respondents];
        newRespondents[index] = value;
        onRespondentsChange(newRespondents);
    };

    const addRespondent = () => {
        const newRespondents = [...respondents, ''];
        onRespondentsChange(newRespondents);
    };

    const removeRespondent = (index: number) => {
        const newRespondents = respondents.filter((_, i) => i !== index);
        onRespondentsChange(newRespondents);
    };

    const currentParticipantCount = respondents.length < Math.min(maxRespondents - 1, MAX_RESPONDENTS);

    return (
        <Flex wrap="wrap" gap={12}>
            {respondents.map((respondent: string, index) => (
                <Flex key={index} className={respondentWrapper} vertical gap={9} style={{ width: 240 }}>
                    <Flex align="center" justify="space-between" style={{ minHeight: 24 }}>
                        <AseedText styles={{ fontSize: 16 }}>{index === 0 ? 'Respondent Name' : `Respondent Name ${index + 1}`}</AseedText>
                        {respondents.length > 1 && (
                            <button className={deleteBtn} onClick={() => removeRespondent(index)}>
                                {/* 􀈑 */}
                                <Icon name="trash" width="16px" height="16px" />
                            </button>
                        )}
                    </Flex>
                    <Input
                        placeholder="Respondent"
                        value={respondent}
                        onChange={e => handleInputChange(index, e.target.value)}
                        maxLength={24}
                    />
                </Flex>
            ))}
            {currentParticipantCount && (
                <Flex
                    vertical
                    gap={9}
                    style={{
                        opacity: 0.5,
                        transition: 'opacity 0.3s',
                        height: '78px',
                    }}
                    onMouseEnter={e => (e.currentTarget.style.opacity = '1')}
                    onMouseLeave={e => (e.currentTarget.style.opacity = '0.5')}
                >
                    <AseedText styles={{ fontSize: 16 }}>Respondent Name</AseedText>

                    <Button
                        type="dashed"
                        style={{
                            width: 240,
                            height: 45,
                            borderRadius: 12,
                            marginTop: 'auto',
                        }}
                        onClick={addRespondent}
                    >
                        Add Respondent
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

export const ReportSetup: React.FC = () => {
    const { reportState, setReportState, isWithInsightReportState, isWithTranscriptState } = useReportSetupContext();

    const { report } = useReportWidgetContext();

    const [isWithTranscript, setIsWithTranscript] = isWithTranscriptState;
    const [isWithInsightReport, setIsWithInsightReport] = isWithInsightReportState;

    const handleInterviewLanguageChange = (value: string) => {
        setReportState(prevState => ({
            ...prevState,
            language: value === AUTOMATIC_DETECTION ? null : (value as Language),
        }));
    };

    const handleReportLanguageChange = (value: string) => {
        setReportState(prevState => ({
            ...prevState,
            report_language: value === SAME_AS_TRANSCRIPT ? null : value,
        }));
    };

    const handleParticipantChange = (value: string) => {
        setReportState(prevState => ({
            ...prevState,
            participant_count: value === AUTOMATIC_DETECTION ? null : parseInt(value),
        }));
    };

    const handleReportGoalsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReportState(prevState => ({
            ...prevState,
            goals: e.target.value || null,
        }));
    };

    const handleReportNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReportState(prevState => ({
            ...prevState,
            notes: e.target.value || null,
        }));
    };

    const handleRespondentsChange = (newRespondents: string[]) => {
        setReportState(prevState => ({
            ...prevState,
            respondent_known_names: newRespondents,
        }));
    };

    const getDisabledStyles = (isEnabled: boolean) =>
        ({
            filter: isEnabled ? 'none' : 'blur(2px)',
            pointerEvents: isEnabled ? 'auto' : 'none',
            opacity: isEnabled ? 1 : 0.7,
            transition: 'all 0.2s ease',
        }) as const;

    return (
        <div className={tabStyles.tabContainer}>
            <div className={tabStyles.contentWrapper}>
                <div className={tabStyles.gradientOverlayTop}></div>
                <div className={tabStyles.tabContent}>
                    <Flex gap={12} vertical>
                        <Flex vertical gap={0}>
                            <Flex justify="space-between" style={{ paddingRight: '5px' }}>
                                <AseedTitle>Transcript</AseedTitle>
                                <SetupCheckBox
                                    isLocked={!report || !report?.transcripts.length}
                                    checked={isWithTranscript}
                                    onChange={checked => setIsWithTranscript(checked)}
                                />
                            </Flex>
                        </Flex>
                        <div style={getDisabledStyles(isWithTranscript)}>
                            <Flex gap={12} vertical>
                                <Informer
                                    text={
                                        <>
                                            For more precise transcriptions, include interview details.{' '}
                                            <em>Optional, but it can make a difference.</em>
                                        </>
                                    }
                                    showIcon={true}
                                    iconName="info"
                                    style={{
                                        paddingBottom: '10px',
                                        background: '#fafafa80',
                                        border: '1px solid #6a6a7010'
                                    }}
                                    textStyle={{ color: '#6a6a70' }}
                                    iconColor="#6a6a70aa"
                                />
                                <Flex vertical gap={12}>
                                    <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
                                        <Flex vertical gap={9} style={{ width: 240 }}>
                                            <AseedText styles={{ fontSize: 16 }}>Interview Language</AseedText>
                                            <Select
                                                style={{ width: '100%' }}
                                                disabled={true}
                                                options={createLanguageOptions(AUTOMATIC_DETECTION)}
                                                defaultValue={reportState.language || AUTOMATIC_DETECTION}
                                                onChange={handleInterviewLanguageChange}
                                            />
                                        </Flex>
                                        <Flex vertical gap={9} style={{ width: 240 }}>
                                            <AseedText styles={{ fontSize: 16 }}>Number of Participants</AseedText>
                                            <Select
                                                style={{ width: '100%' }}
                                                options={createParticipantOptions(MAX_PARTICIPANTS)}
                                                defaultValue={reportState.participant_count?.toString() || AUTOMATIC_DETECTION}
                                                onChange={handleParticipantChange}
                                            />
                                        </Flex>
                                    </div>
                                    <Flex gap={12}>
                                        <RespondentInput
                                            maxRespondents={reportState.participant_count ?? MAX_PARTICIPANTS}
                                            respondents={reportState.respondent_known_names}
                                            onRespondentsChange={handleRespondentsChange}
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </div>
                    </Flex>

                    <Flex gap={12} vertical style={{ marginTop: 48 }}>
                        <Flex vertical gap={0}>
                            <Flex justify="space-between" style={{ paddingRight: '5px' }}>
                                <AseedTitle>Research Context</AseedTitle>
                                <SetupCheckBox checked={isWithInsightReport} onChange={checked => setIsWithInsightReport(checked)} />
                            </Flex>
                        </Flex>
                        <div style={getDisabledStyles(isWithInsightReport)}>
                            <Flex gap={9} vertical>
                                <Informer
                                    text={
                                        <>
                                            <strong>Important</strong>
                                            <p>Providing product details and research goals is key to a more accurate report.</p>
                                            <p>
                                                <strong>
                                                    <em>What to Include:</em>
                                                </strong>
                                            </p>
                                            <ul>
                                                <li>
                                                    <strong>Product Description:</strong> What does it do, and who's it for?
                                                </li>
                                                <li>
                                                    <strong>Research Goals:</strong> What questions or hypotheses are you exploring?
                                                </li>
                                                <li>
                                                    <strong>Early Observations:</strong> Any patterns or insights you've already noticed.
                                                </li>
                                            </ul>
                                            Whether it's a quick summary or a detailed overview, the more context you share, the better Aseed
                                            can interpret your transcripts and deliver insights that truly matter.
                                        </>
                                    }
                                    showIcon={true}
                                    iconName="bulb"
                                    iconWidth={14}
                                    iconHeight={16}
                                />
                                <Input.TextArea
                                    placeholder={TEXT_AREA_GOALS}
                                    autoSize={{ minRows: 6, maxRows: 13 }}
                                    style={{ marginTop: 4 }}
                                    onChange={handleReportGoalsChange}
                                    value={reportState.goals || ''}
                                    showCount
                                    maxLength={10000}
                                />

                                <Informer
                                    text={
                                        <>
                                            <strong>Additional Info for Accurate Results</strong> <p></p>
                                            Feel free to include any notes, observations, or questions that might help interpret your
                                            interviews. The more context you provide, the more accurate and relevant your final report
                                            will be.
                                        </>
                                    }
                                    showIcon={true}
                                    iconName="bulb"
                                    iconWidth={14}
                                    iconHeight={16}
                                    style={{
                                        marginTop: '24px',
                                        paddingBottom: '10px',
                                        background: '#F5FEFA',
                                        border: '1px solid #C7F0E2'
                                    }}
                                    textStyle={{ color: '#097B5D' }}
                                />

                                <Input.TextArea
                                    placeholder={TEXT_AREA_NOTES}
                                    autoSize={{ minRows: 4, maxRows: 5 }}
                                    style={{ marginTop: 4 }}
                                    onChange={handleReportNotesChange}
                                    value={reportState.notes || ''}
                                    showCount
                                    maxLength={20000}
                                />

                                <Flex vertical gap={9} style={{ paddingTop: 16, paddingBottom: 120}}>
                                    <AseedText styles={{ fontSize: 16 }}>Report Language</AseedText>
                                    <Select
                                        style={{ width: 240 }}
                                        options={createLanguageOptions(SAME_AS_TRANSCRIPT)}
                                        defaultValue={reportState.report_language || SAME_AS_TRANSCRIPT}
                                        onChange={handleReportLanguageChange}
                                    />
                                </Flex>
                            </Flex>
                        </div>
                    </Flex>
                </div>
                <div 
                    className={tabStyles.gradientOverlayBottom}
                    style={{ height: '120px' }}
                ></div>
                <div className={tabStyles.fixedReportGenerateMenu}>
                    <ReportGenerateMenu />
                </div>
            </div>
        </div>
    );
};
