import React from 'react';

import { Space } from 'antd';

import { Icon } from 'src/components/Common/Icon';

import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { useJTBDExpandedKeys } from '../../../../helpers/useExpandedKeys';
import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { IdentifiedJobs } from '../../../../renders/RenderIdentifiedJobs';
import { JTBD, TranscriptExample } from 'src/@types/report';

import styles from '../../ReportTabs.module.scss';

interface ReportJTBDProps {
    jtbd: JTBD;
    onSourceClick?: (example: TranscriptExample) => void;
}

export const ReportJTBD: React.FC<ReportJTBDProps> = ({ jtbd, onSourceClick }) => {
    const links = getAnchorLinks(jtbd);
    const { expandedKeys, setExpandedKeys, toggleExpandAll } = useJTBDExpandedKeys(jtbd);

    return (
        <div className={styles.tabContainer}>
            <div className={styles.anchorContainer}>
                <AnchorLinks links={links} />
                <button
                    type="button"
                    className={styles.expandButton}
                    onClick={toggleExpandAll}
                    aria-label={expandedKeys.length > 0 ? 'Collapse all sections' : 'Expand all sections'}
                >
                    {expandedKeys.length > 0 ? 'Collapse all' : 'Expand all'}
                </button>
            </div>
            <div className={styles.gradientOverlayTop} style={{ top: '29.5px' }}></div>
            <div className={styles.contentWrapper} style={{ paddingTop: '0px' }}>
                <div className={styles.tabContent}>
                    <div
                        id="identified-jobs"
                        style={{
                            borderRadius: '16px',
                            padding: '16px',
                            margin: '0',
                        }}
                    >
                        <Space direction="vertical" size={12} style={{ width: '100%' }}>
                            <h2
                                style={{
                                    transition: 'font-size 0.2s ease',
                                    fontSize: '18px',
                                    lineHeight: '130%',
                                    margin: 0,
                                    fontVariant: 'normal',
                                    fontWeight: 'bold',
                                }}
                            >
                                Identified Jobs
                            </h2>
                            <IdentifiedJobs
                                jobs={jtbd.identified_jobs}
                                expandedKeys={expandedKeys}
                                setExpandedKeys={setExpandedKeys}
                                onSourceClick={onSourceClick}
                            />
                        </Space>
                    </div>
                    <ReportDescriptionSection
                        id="jtbd_solutions"
                        title="Solutions"
                        items={jtbd.solutions}
                        iconSymbol={
                            <Icon
                                name="handPoint"
                                width={20}
                                height={20}
                                fill="currentColor"
                                className={styles.anchorIcon}
                                style={{ transform: 'translateY(-1px)' }}
                            />
                        }
                        expandedKeys={expandedKeys}
                        setExpandedKeys={setExpandedKeys}
                    />
                    <ReportDescriptionSection
                        id="jtbd_outcomes"
                        title="Outcomes"
                        items={jtbd.outcomes}
                        iconSymbol={<Icon name="rosette" width={17} height={17} fill="currentColor" className={styles.anchorIcon} />}
                        expandedKeys={expandedKeys}
                        setExpandedKeys={setExpandedKeys}
                    />
                    <div
                        style={{
                            height: '60px',
                            minHeight: '60px',
                            flexShrink: 0,
                            width: '100%',
                        }}
                    />
                </div>
                <div className={styles.gradientOverlayBottom}></div>
            </div>
        </div>
    );
};

const getAnchorLinks = (jtbd: JTBD): AnchorLinkParams[] => {
    return [
        {
            href: '#identified-jobs',
            title: 'Identified Jobs',
            iconSymbol: <Icon name="hammerCircle" width={15} height={15} fill="currentColor" className={styles.anchorIcon} />,
            count: jtbd.identified_jobs.length,
        },
        {
            href: '#jtbd_solutions',
            title: 'Solutions',
            iconSymbol: (
                <Icon
                    name="handPoint"
                    width={17}
                    height={17}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(1px)' }}
                />
            ),
            count: jtbd.solutions.length,
        },
        {
            href: '#jtbd_outcomes',
            title: 'Outcomes',
            iconSymbol: <Icon name="rosette" width={16} height={16} fill="currentColor" className={styles.anchorIcon} />,
            count: jtbd.outcomes.length,
        },
    ];
};
