export const infofacts: string[] = [
  `<p>
    We're updating our insights like <strong>Bayesian inference</strong>—each new piece of user data refines our design assumptions.
  </p>
  <ul>
    <li>Embrace evidence to continuously evolve your UX strategy.</li>
    <li>Let fresh data guide your next design iteration.</li>
  </ul>
  <p>
    Stay adaptive for smarter, data-driven decisions!
  </p>`,

  `<p>
    Iterative testing is the heartbeat of exceptional design.
  </p>
  <ul>
    <li>Prototype, test, and refine until perfection emerges.</li>
    <li>Every cycle reveals hidden insights about user behavior.</li>
  </ul>
  <p>
    Keep experimenting and innovating with each iteration.
  </p>`,

  `<p>
    Qualitative coding transforms raw feedback into organized insights.
  </p>
  <ul>
    <li>Sort through user comments to uncover meaningful patterns.</li>
    <li>Turn chaos into clarity with methodical analysis.</li>
  </ul>
  <p>
    Discover the story behind every piece of feedback.
  </p>`,

  `<p>
    Usability testing has roots in early computing experiments.
  </p>
  <ul>
    <li>Pioneers experimented to create the intuitive interfaces we rely on today.</li>
    <li>History shows that great design is built on trial and error.</li>
  </ul>
  <p>
    Learn from the past to innovate for the future.
  </p>`,

  `<p>
    Nielsen's heuristics serve as the DNA of user-centered design.
  </p>
  <ul>
    <li>Timeless principles that guide every design decision.</li>
    <li>Rely on these proven rules to enhance usability.</li>
  </ul>
  <p>
    Trust in established guidelines for a smoother user experience.
  </p>`,

  `<p>
    Cognitive biases can sneak into UX research and cloud our judgment.
  </p>
  <ul>
    <li>Question your initial assumptions to uncover true user needs.</li>
    <li>Challenge biases for a clearer view of user behavior.</li>
  </ul>
  <p>
    Clear insights emerge when biases are set aside.
  </p>`,

  `<p>
    Eye-tracking studies reveal fascinating patterns in user behavior.
  </p>
  <ul>
    <li>Most users follow an F-pattern when scanning a page.</li>
    <li>Design layouts that align with natural reading habits.</li>
  </ul>
  <p>
    Use these insights to create more intuitive interfaces.
  </p>`,

  `<p>
    Crafting user personas is like creating characters in a compelling story.
  </p>
  <ul>
    <li>Each persona represents distinct traits and user needs.</li>
    <li>They guide your design decisions with empathy and precision.</li>
  </ul>
  <p>
    Bring your product to life by truly knowing your users.
  </p>`,

  `<p>
    A/B testing is your experimental lab for digital design.
  </p>
  <ul>
    <li>Even minor tweaks can yield surprising user engagement improvements.</li>
    <li>Let real data drive your design decisions.</li>
  </ul>
  <p>
    Discover what truly resonates with your audience through experimentation.
  </p>`,

  `<p>
    User journey maps are treasure maps for uncovering hidden UX gems.
  </p>
  <ul>
    <li>Visualize each step of the user's experience.</li>
    <li>Identify pain points and delightful moments along the way.</li>
  </ul>
  <p>
    Navigate your design process like a seasoned explorer.
  </p>`,

  `<p>
    Empathy is the secret ingredient in successful UX research.
  </p>
  <ul>
    <li>Understanding user frustrations leads to breakthrough solutions.</li>
    <li>Connect with users on a human level to inspire innovative designs.</li>
  </ul>
  <p>
    Let empathy guide every decision you make.
  </p>`,

  `<p>
    Remote usability testing opens doors to a global pool of insights.
  </p>
  <ul>
    <li>Collect diverse perspectives without leaving your desk.</li>
    <li>Leverage digital tools to reach users from around the world.</li>
  </ul>
  <p>
    Expand your research horizons and embrace global diversity.
  </p>`,

  `<p>
    Emotional design bridges functionality and human connection.
  </p>
  <ul>
    <li>Create experiences that resonate on a personal level.</li>
    <li>Balance aesthetics with usability for lasting impact.</li>
  </ul>
  <p>
    Design with both the heart and the mind.
  </p>`,

  `<p>
    Inclusive design ensures every user feels seen and valued.
  </p>
  <ul>
    <li>Design for diverse abilities and cultural backgrounds.</li>
    <li>Small adjustments can dramatically improve accessibility.</li>
  </ul>
  <p>
    Build products that truly welcome everyone.
  </p>`,

  `<p>
    Data storytelling transforms raw numbers into compelling narratives.
  </p>
  <ul>
    <li>Combine quantitative metrics with qualitative insights.</li>
    <li>Engage your audience with stories that bring data to life.</li>
  </ul>
  <p>
    Make your insights not just visible, but truly understood.
  </p>`,

  `<p>
    Microinteractions are the tiny details that elevate user experience.
  </p>
  <ul>
    <li>Small animations and feedback loops can delight users.</li>
    <li>Focus on details to create memorable interactions.</li>
  </ul>
  <p>
    Sometimes, the smallest touch makes the biggest impact.
  </p>`,

  `<p>
    Iteration is an art—each cycle refines your design toward perfection.
  </p>
  <ul>
    <li>Every prototype brings you closer to an ideal solution.</li>
    <li>Embrace continuous improvement as part of your creative journey.</li>
  </ul>
  <p>
    Celebrate each step forward in your UX adventure.
  </p>`,

  `<p>
    Ethnographic research offers a window into users' natural environments.
  </p>
  <ul>
    <li>Observe real-life settings to capture genuine user behavior.</li>
    <li>Contextual insights reveal more than surveys alone can show.</li>
  </ul>
  <p>
    Let authentic observations drive your design choices.
  </p>`,

  `<p>
    Mixed-method research blends the strengths of qualitative and quantitative approaches.
  </p>
  <ul>
    <li>Numbers and narratives together paint a fuller picture.</li>
    <li>Two perspectives often lead to more robust insights.</li>
  </ul>
  <p>
    Embrace the power of combining methods for deeper understanding.
  </p>`,

  `<p>
    Open-ended questions unlock unexpected insights during user interviews.
  </p>
  <ul>
    <li>Encourage users to share their experiences in their own words.</li>
    <li>Sometimes broad questions lead to the most valuable discoveries.</li>
  </ul>
  <p>
    Listen actively and let the conversation guide you.
  </p>`,

  `<p>
    Tracking UX metrics is like having a fitness tracker for your design.
  </p>
  <ul>
    <li>Monitor task success, engagement, and usability in real time.</li>
    <li>Measure, analyze, and continuously improve user experiences.</li>
  </ul>
  <p>
    Let data drive your journey toward optimal design.
  </p>`,

  `<p>
    The term <strong>"User Experience"</strong> was popularized in the '90s by Don Norman.
  </p>
  <ul>
    <li>This shift in thinking revolutionized digital design forever.</li>
    <li>Innovation often begins with a bold new perspective.</li>
  </ul>
  <p>
    Celebrate the pioneers who reshaped how we interact with technology.
  </p>`,

  `<p>
    Reducing cognitive load can transform complex interfaces into seamless experiences.
  </p>
  <ul>
    <li>Simplify design to make tasks feel effortless for your users.</li>
    <li>Less mental strain means more engaged and satisfied users.</li>
  </ul>
  <p>
    Prioritize clarity to boost both usability and delight.
  </p>`,

  `<p>
    A clear visual hierarchy guides users effortlessly through your content.
  </p>
  <ul>
    <li>Highlight key elements with strategic use of <strong>bold</strong> and <em>color</em>.</li>
    <li>Organize information so that nothing important is overlooked.</li>
  </ul>
  <p>
    Let your design naturally lead the way.
  </p>`,

  `<p>
    A/B testing isn't just about numbers—it's an experiment in design psychology.
  </p>
  <ul>
    <li>Minor tweaks can reveal surprising user preferences.</li>
    <li>Discover the subtle elements that truly resonate with your audience.</li>
  </ul>
  <p>
    Experiment boldly and learn from every result.
  </p>`,

  `<p>
    Cultural nuances shape how users interact with digital products.
  </p>
  <ul>
    <li>Design with sensitivity to regional preferences and traditions.</li>
    <li>A creative twist may be needed to resonate across cultures.</li>
  </ul>
  <p>
    Embrace diversity to craft globally appealing designs.
  </p>`,

  `<p>
    A touch of humor in UX can turn a mundane error into a delightful surprise.
  </p>
  <ul>
    <li>Playful microcopy and witty illustrations can lighten the mood.</li>
    <li>Transform frustration into a memorable moment with a smile.</li>
  </ul>
  <p>
    Let humor be the secret ingredient in your design toolkit.
  </p>`,

  `<p>
    A continuous feedback loop is the key to sustained UX improvement.
  </p>
  <ul>
    <li>Regularly gather and analyze user insights to refine your design.</li>
    <li>Iterate based on real feedback for increasingly intuitive interfaces.</li>
  </ul>
  <p>
    Listen, learn, and evolve with every user interaction.
  </p>`,

  `<p>
    Every design iteration is a mini-adventure filled with unexpected discoveries.
  </p>
  <ul>
    <li>View each prototype as a journey toward a more delightful UX.</li>
    <li>Each test reveals new possibilities and areas for improvement.</li>
  </ul>
  <p>
    Embrace the adventure and enjoy every step of the journey.
  </p>`,

  `<p>
    UX research is a delicate balance between art and science.
  </p>
  <ul>
    <li>Merge creative intuition with analytical rigor for breakthrough designs.</li>
    <li>This harmonious blend sparks innovative and effective solutions.</li>
  </ul>
  <p>
    Celebrate the power of creativity meeting precision in every project.
  </p>`
  // остальные варианты...
];