import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { GoogleOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, Form, FormProps, Input, theme } from 'antd';

import { useBalance } from 'src/components/Menu/BalanceContext';

import authService from '../../services/auth.service';
import { firebaseAuth } from '../../services/firebase/AseedFirebase';
import { profileService } from '../../services/profile.service';
import { ModalContext } from './ModalContext';
import { ModalPages } from './index';
import { PATH } from 'src/constants/paths';

type FieldType = {
    refcode: string;
    fullname: string;
    email: string;
    password: string;
    agree: boolean;
};

interface ConfirmEmailProps {
    setEmail: Dispatch<SetStateAction<string>>;
    onCancel: Dispatch<SetStateAction<void>>;
    initialRefCode?: string;
}

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
};

export const SignUp: React.FC<ConfirmEmailProps> = ({ setEmail, onCancel, initialRefCode = '' }) => {
    const navigate = useNavigate();
    const {
        token: { colorPrimary, margin, marginMD, marginXL, marginXXL, marginXS, sizeXXL, paddingLG, fontSizeLG, colorWhite, colorError },
    } = theme.useToken();

    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const { setModalPage } = useContext(ModalContext);
    const { refreshBalance } = useBalance();

    const [form] = Form.useForm<FieldType>();

    useEffect(() => {
        if (initialRefCode) {
            form.setFieldsValue({ refcode: initialRefCode });
        }
    }, [initialRefCode, form]);

    const onFinish: FormProps<FieldType>['onFinish'] = async ({ refcode, email, password, fullname }) => {
        setLoading(true);
        try {
            await authService.signUp(email, password, fullname);
            await profileService.ensureProfile(refcode, true);
            //await profileService.verifyEmail();
            setModalPage(ModalPages.ConfirmEmail);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSignIn = async () => {
        setLoading(true);
        try {
            const refcode = form.getFieldValue('refcode') || undefined;
            await authService.signInWithGoogle();
            await profileService.ensureProfile(refcode);
            await refreshBalance();
            onCancel();
            navigate(PATH.WORKSPACE);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Flex justify="center" wrap style={{ marginTop: marginXL }}>
            {/* Внутренний контейнер с фиксированной шириной для правильного выравнивания */}
            <div style={{ width: 330 }}>
                {/* Кнопка "Sign Up with Google" */}
                <Button
                    icon={<GoogleOutlined />}
                    size="large"
                    onClick={handleGoogleSignIn}
                    disabled={loading}
                    loading={loading}
                    style={{ borderRadius: sizeXXL, height: sizeXXL, width: '100%' }}
                >
                    Sign up with Google
                </Button>

                {/* Разделитель с кастомными стилями */}
                <Divider
                    style={{
                        marginTop: 32,
                        marginBottom: 32,
                        color: '#9EA3AE',
                        textAlign: 'center',
                        fontSize: 12,
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '140%',
                        cursor: 'default',
                    }}
                >
                    Or continue with email
                </Divider>

                {/* Форма для регистрации */}
                <Form
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="on"
                    style={{ width: '100%' }}
                >
                    <Form.Item<FieldType> name="refcode" rules={[{ required: false }]} style={{ marginBottom: marginMD }}>
                        <Input
                            size="large"
                            placeholder="Your Invitation Code"
                            style={{
                                height: sizeXXL,
                                borderRadius: sizeXXL,
                                paddingLeft: paddingLG,
                                paddingRight: paddingLG,
                                fontSize: fontSizeLG + 2,
                                color: '#6A6A70',
                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                            }}
                        />
                    </Form.Item>

                    <Form.Item<FieldType>
                        name="fullname"
                        rules={[{ required: true, message: 'Please input your full name' }]}
                        style={{ marginBottom: marginMD }}
                    >
                        <Input
                            size="large"
                            placeholder="Your full name"
                            style={{
                                height: sizeXXL,
                                borderRadius: sizeXXL,
                                paddingLeft: paddingLG,
                                paddingRight: paddingLG,
                                fontSize: fontSizeLG + 2,
                                color: '#6A6A70',
                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                            }}
                        />
                    </Form.Item>

                    <Form.Item<FieldType>
                        name="email"
                        rules={[{ required: true, message: 'Please input your email' }]}
                        style={{ marginBottom: marginMD }}
                    >
                        <Input
                            size="large"
                            placeholder="Your email"
                            style={{
                                height: sizeXXL,
                                borderRadius: sizeXXL,
                                paddingLeft: paddingLG,
                                paddingRight: paddingLG,
                                fontSize: fontSizeLG + 2,
                                color: '#6A6A70',
                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                            }}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item<FieldType>
                        name="password"
                        rules={[{ required: true, message: 'Please input your password' }]}
                        style={{ marginBottom: marginXXL }}
                    >
                        <Input.Password
                            size="large"
                            placeholder="Your password"
                            style={{
                                height: sizeXXL,
                                borderRadius: sizeXXL,
                                paddingLeft: paddingLG,
                                paddingRight: paddingLG,
                                fontSize: fontSizeLG + 2,
                                color: '#6A6A70',
                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                            }}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            disabled={loading}
                            loading={loading}
                            style={{ borderRadius: sizeXXL, height: sizeXXL, width: '100%' }}
                        >
                            Sign Up
                        </Button>
                    </Form.Item>
                </Form>

                {/* Вывод ошибки, если она есть */}
                {error && <Divider style={{ borderColor: colorWhite, marginTop: marginXXL, color: colorError }}>{error}</Divider>}

                {/* Блок "Do you have an Account? Sign In" */}
                <Flex justify="center" style={{ fontSize: fontSizeLG + 2, marginBottom: margin }}>
                    <div style={{ color: '#6A6A70', marginRight: marginXS}}>Already have an Account?</div>
                    <div style={{ color: colorPrimary, cursor: 'pointer' }} onClick={() => setModalPage(ModalPages.SignIn)}>
                        Sign In
                    </div>
                </Flex>
            </div>
        </Flex>
    );
};
