import { SettingOutlined } from '@ant-design/icons';
import { Radio, Switch } from 'antd';
import { Popover } from 'antd';
import type { RadioChangeEvent } from 'antd';

import styles from './SearchSettings.module.scss';

export type SearchScope = 'all' | 'transcript' | 'report';

interface SearchSettingsProps {
    searchScope: SearchScope;
    useInternet: boolean;
    isTranscriptOnly: boolean;
    onSearchScopeChange: (scope: SearchScope) => void;
    onUseInternetChange: (checked: boolean) => void;
}

export const SearchSettings: React.FC<SearchSettingsProps> = ({
    searchScope,
    useInternet,
    isTranscriptOnly,
    onSearchScopeChange,
    onUseInternetChange,
}) => {
    const content = (
        <div className={styles.settingsContent}>
            {!isTranscriptOnly && (
                <div className={styles.settingsSection}>
                    <h4>Search scope</h4>
                    <Radio.Group value={searchScope} onChange={(e: RadioChangeEvent) => onSearchScopeChange(e.target.value)}>
                        <Radio.Button value="all">All</Radio.Button>
                        <Radio.Button value="transcript">Transcript</Radio.Button>
                        <Radio.Button value="report">Report</Radio.Button>
                    </Radio.Group>
                </div>
            )}

            <div className={styles.settingsSection}>
                <h4>Additional</h4>
                <div className={styles.settingRow}>
                    <span>Search in the Web</span>
                    <Switch checked={useInternet} onChange={onUseInternetChange} />
                </div>
            </div>
        </div>
    );

    return (
        <Popover content={content} trigger="click" placement="topRight">
            <button className={styles.settingsButton}>
                <SettingOutlined style={{ fontSize: '16px' }} />
            </button>
        </Popover>
    );
};
