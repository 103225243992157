import React from 'react';

import { ConfigProvider } from 'antd';

import { useIsMobile } from '../components/Common/DynamicInfo';

import { Routes } from 'src/routes';

import './index.css';

function App() {
    const isMobile = useIsMobile();

    return (
        <ConfigProvider
            theme={{
                cssVar: true,
                token: {
                    // colors
                    colorPrimary: '#2B2B30', // '#8038FF',
                    colorLink: '#6a6a70',
                    colorError: '#E57373',
                    colorWarning: '#E84F52', //trash
                    colorInfo: '#8038FF',
                    colorTextSecondary: '#3D3D3D',
                    fontSizeIcon: 30,

                    // text
                    fontFamily: '-apple-system, BlinkMacSystemFont, system-ui, sans-serif',
                    fontSize: isMobile ? 14 : 16,
                    fontSizeHeading2: 32,
                    fontSizeHeading3: 24,
                    fontSizeXL: 22,
                    paddingXL: 36,
                },
                components: {
                    Layout: {
                        headerBg: '#8038FF',
                    },
                    Button: {
                        borderRadiusLG: 14,
                    },
                    Modal: {
                        borderRadiusLG: 24,
                        colorBgElevated: 'rgba(255, 255, 255, 0.7)',
                        headerBg: 'none',
                    },
                    Collapse: {
                        contentPadding: '0 42px',
                        paddingSM: 0,
                        headerPadding: '6px 12px',
                    },
                    Tabs: {
                        itemActiveColor: '#8038FF',
                        titleFontSize: 16,
                        itemSelectedColor: '#8038FF',
                        cardBg: '#8038FF05',
                        cardGutter: 5,
                    },
                    Segmented: {
                        // itemSelectedColor: '#8038FF',
                        itemSelectedColor: '#FFFFFF',
                        itemSelectedBg: '#2B2B30',
                        // itemSelectedBg: '#4497fa', // Blue
                        // itemSelectedBg: '#5395FF', // Blue
                        // itemSelectedBg: '#8038FFCC', // Violet
                        // itemHoverColor: '#8038FF',
                        itemHoverBg: '#ECECF2',
                        // trackBg: '#f8f8ff',
                        trackBg: '#f4f5f8', //'#FFFFFF',
                    },
                    Anchor: {
                        linkPaddingBlock: 2,
                    },
                    Select: {
                        fontSizeIcon: 16,
                        controlHeight: 45,
                        borderRadius: 12,
                    },
                    Input: {
                        controlHeight: 45,
                        borderRadius: 12,
                    },
                },
            }}
        >
            <Routes />
        </ConfigProvider>
    );
}

export default App;
