import React from 'react';

import { Icon } from '../../../../../Common/Icon';
import { AnchorLinkParams, AnchorLinks } from '../../../../AnchorLinks/AnchorLinks';
import { useOverviewExpandedKeys } from '../../../../helpers/useExpandedKeys';
import { ReportInsights } from './ReportInsights';
import { ReportProfile } from './ReportProfile';
import { ReportRecommendations } from './ReportRecommendations';
import { Insights, Profile, Recommendations, TranscriptExample } from 'src/@types/report';

import tabStyles from '../../ReportTabs.module.scss';

interface ReportOverviewProps {
    profile: Profile;
    insights: Insights;
    recommendations: Recommendations;
    onSourceClick?: (example: TranscriptExample) => void;
}

export const ReportOverview: React.FC<ReportOverviewProps> = ({ profile, insights, recommendations, onSourceClick }) => {
    const { expandedKeys, setExpandedKeys, toggleExpandAll } = useOverviewExpandedKeys(profile, insights, recommendations);

    return (
        <div className={tabStyles.tabContainer}>
            <div className={tabStyles.anchorContainer}>
                <AnchorLinks links={getOverviewLinks()} />
                <button
                    type="button"
                    className={tabStyles.expandButton}
                    onClick={toggleExpandAll}
                    aria-label={expandedKeys.length > 0 ? 'Collapse all sections' : 'Expand all sections'}
                >
                    {expandedKeys.length > 0 ? 'Collapse all' : 'Expand all'}
                </button>
            </div>
            <div className={tabStyles.gradientOverlayTop} style={{ top: '29.5px' }}></div>
            <div className={tabStyles.contentWrapper} style={{ paddingTop: '12px' }}>
                <div className={tabStyles.tabContent}>
                    <div id="profile">
                        <h2 className={tabStyles.projectTitle}>Profile</h2>
                        <ReportProfile
                            profile={profile}
                            expandedKeys={expandedKeys}
                            setExpandedKeys={setExpandedKeys}
                            onSourceClick={onSourceClick}
                        />
                    </div>
                    <div id="insights" style={{ marginTop: '24px' }}>
                        <h2 className={tabStyles.projectTitle}>Insights</h2>
                        <ReportInsights
                            insights={insights}
                            expandedKeys={expandedKeys}
                            setExpandedKeys={setExpandedKeys}
                            onSourceClick={onSourceClick}
                        />
                    </div>
                    <div id="recommendations" style={{ marginTop: '24px' }}>
                        <h2 className={tabStyles.projectTitle}>Recommendations</h2>
                        <ReportRecommendations
                            recommendations={recommendations}
                            expandedKeys={expandedKeys}
                            setExpandedKeys={setExpandedKeys}
                        />
                    </div>
                </div>
                <div className={tabStyles.gradientOverlayBottom}></div>
            </div>
        </div>
    );
};

const getOverviewLinks = (): AnchorLinkParams[] => {
    return [
        {
            href: '#profile',
            title: 'Profile',
            iconSymbol: (
                <Icon
                    name="personFill"
                    width={17.5}
                    height={17.5}
                    fill="currentColor"
                    className={tabStyles.anchorIcon}
                    style={{ transform: 'translateY(0.25px)' }}
                />
            ),
            count: 6,
        },
        {
            href: '#insights',
            title: 'Insights',
            iconSymbol: (
                <Icon
                    name="sparkle"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={tabStyles.anchorIcon}
                    style={{ transform: 'translateY(2.5px)' }}
                />
            ),
            count: 4,
        },
        {
            href: '#recommendations',
            title: 'Recommendations',
            iconSymbol: (
                <Icon
                    name="chevronRightDotted"
                    width={15}
                    height={15}
                    fill="currentColor"
                    className={tabStyles.anchorIcon}
                    style={{ transform: 'translateY(2.5px)' }}
                />
            ),
            count: 3,
        },
    ];
};
