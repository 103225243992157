import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { GoogleOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, Form, FormProps, Input, theme } from 'antd';

import { useBalance } from 'src/components/Menu/BalanceContext';

import authService from '../../services/auth.service';
import { profileService } from '../../services/profile.service';
import { ModalContext } from './ModalContext';
import { ModalPages } from './index';
import { PATH } from 'src/constants/paths';

type FieldType = {
  email: string;
  password: string;
  agree: boolean;
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = errorInfo => {
  console.log('Failed:', errorInfo);
};

interface SignInProps {
  onCancel: Dispatch<SetStateAction<void>>;
  setEmail: Dispatch<SetStateAction<string>>;
}

export const SignIn: React.FC<SignInProps> = ({ onCancel, setEmail }) => {
  const navigate = useNavigate();
  const {
    token: {
      colorPrimary,
      margin,
      marginMD,
      marginXL,
      marginLG,
      marginXXL,
      marginXS,
      sizeXXL,
      paddingLG,
      fontSizeLG,
      colorWhite,
      colorError,
    },
  } = theme.useToken();

  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const { setModalPage } = useContext(ModalContext);
  const { refreshBalance } = useBalance();

  const onFinish: FormProps<FieldType>['onFinish'] = async ({ email, password }) => {
    setLoading(true);
    try {
      const credential = await authService.signIn(email, password);
      if (!credential.user.emailVerified) {
        setError('☝️ Please verify email');
        return;
      }
      await profileService.ensureProfile();
      await refreshBalance();
      onCancel();
      navigate(PATH.WORKSPACE);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      await authService.signInWithGoogle();
      await profileService.ensureProfile();
      await refreshBalance();
      onCancel();
      navigate(PATH.WORKSPACE);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex justify="center" wrap style={{ marginTop: marginXL }}>
      {/* Внутренний контейнер с фиксированной шириной для правильного выравнивания */}
      <div style={{ width: 330 }}>
        {/* Кнопка "Sign up with Google" */}
        <Button
          icon={<GoogleOutlined />}
          size="large"
          onClick={handleGoogleSignIn}
          disabled={loading}
          loading={loading}
          style={{ borderRadius: sizeXXL, height: sizeXXL, width: '100%' }}
        >
          Sign in with Google
        </Button>

        {/* Разделитель */}
        <Divider style={{
            marginTop: 32,
            marginBottom: 32,
            color: '#9EA3AE',
            textAlign: 'center',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '140%',
            cursor: 'default'
            }}>
            Or continue with email
        </Divider>

        {/* Форма для авторизации по email */}
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
          style={{ width: '100%' }}
        >
          <Form.Item<FieldType>
            name="email"
            rules={[{ required: true, message: 'Please input your email' }]}
            style={{ marginBottom: marginMD }}
          >
            <Input
              size="large"
              placeholder="Your email"
              style={{
                height: sizeXXL,
                borderRadius: sizeXXL,
                paddingLeft: paddingLG,
                paddingRight: paddingLG,
                fontSize: fontSizeLG + 2,
                color: '#6A6A70',
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
              }}
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Item>

          <Form.Item<FieldType>
            name="password"
            rules={[{ required: true, message: 'Please input your password' }]}
            style={{ marginBottom: marginMD }}
          >
            <Input.Password
              size="large"
              placeholder="Your password"
              style={{
                height: sizeXXL,
                borderRadius: sizeXXL,
                paddingLeft: paddingLG,
                paddingRight: paddingLG,
                fontSize: fontSizeLG + 2,
                color: '#6A6A70',
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
              }}
            />
          </Form.Item>

          {/* Ссылка "forgot password" */}
          <Flex justify="end" style={{ marginTop: -margin, marginBottom: marginLG }}>
            <a
                onClick={() => setModalPage(ModalPages.ForgotPassword)}
                style={{
                overflow: 'hidden',
                color: '#212123',
                fontSize: '14px',
                fontStyle: 'normal',
                // fontWeight: 510,
                lineHeight: '120%',
                letterSpacing: '-0.24px',
                }}
            >
                forgot password
            </a>
            </Flex>

          {/* Кнопка "Sign In" */}
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={loading}
              loading={loading}
              style={{ borderRadius: sizeXXL, height: sizeXXL, width: '100%' }}
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>

        {/* Вывод ошибки, если она есть */}
        {error && (
          <Divider style={{ borderColor: colorWhite, marginTop: marginXXL, color: colorError }}>
            {error}
          </Divider>
        )}

        {/* Блок "Want create an Account? Sign Up" */}
        <Flex justify="center" style={{ fontSize: fontSizeLG + 2, marginBottom: margin }}>
          <div style={{ color: '#6A6A70', marginRight: marginXS }}>Don't have an account?</div>
          <div style={{ color: colorPrimary, cursor: 'pointer' }} onClick={() => setModalPage(ModalPages.SignUp)}>
            Sign Up
          </div>
        </Flex>
      </div>
    </Flex>
  );
};