import { Insights, JTBD, Job, Profile, Recommendations } from '../../../@types/report';

export const getJTBDKeys = (jtbd: JTBD): string[] => {
    const keys: string[] = [];

    // Jobs keys
    keys.push(...getJobKeys(jtbd.identified_jobs));

    // Solutions and outcomes
    keys.push(...jtbd.solutions.map((_, i) => `jtbd_solutions-${i}`));
    keys.push(...jtbd.outcomes.map((_, i) => `jtbd_outcomes-${i}`));

    return keys;
};

export const getOverviewKeys = (profile: Profile, insights: Insights, recommendations: Recommendations): string[] => {
    const keys: string[] = [];

    // Profile section
    keys.push(
        ...profile.goals.map((_, i) => `goals-${i}`),
        ...profile.motivations.map((_, i) => `motivations-${i}`),
        ...profile.pain_points.map((_, i) => `pain-points-${i}`),
        ...profile.emotions.map((_, i) => `emotions-${i}`),
        ...profile.tools.map((_, i) => `tools-${i}`)
    );

    // Insights section
    keys.push(
        ...insights.key_insights.map((_, i) => `key-insights-${i}`),
        ...insights.barriers.map((_, i) => `barriers-${i}`),
        ...insights.engagements.map((_, i) => `engagements-${i}`),
        ...insights.expectations.map((_, i) => `expectations-${i}`)
    );

    // Recommendations section
    keys.push(
        ...recommendations.actionable_steps.map((_, i) => `actionable-steps-${i}`),
        ...recommendations.product_improvements.map((_, i) => `product-improvements-${i}`),
        ...recommendations.service_enhancements.map((_, i) => `service-enhancements-${i}`)
    );

    return keys;
};

const getJobKeys = (jobs: Job[], prefix: string = ''): string[] => {
    return jobs.flatMap((job, index) => {
        const jobPrefix = prefix ? `${prefix}.${index + 1}` : `${index + 1}`;
        const keys = [`job-${jobPrefix}`];

        if (job.examples?.length > 0) {
            keys.push(`job-${jobPrefix}-examples`);
        }

        if (job.sub_jobs?.length > 0) {
            keys.push(...getJobKeys(job.sub_jobs, jobPrefix));
        }

        return keys;
    });
};
