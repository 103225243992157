import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Flex, Input, message } from 'antd';

import { Icon } from 'src/components/Common/Icon';

import { AseedText } from '../../../../../AseedTypography/AseedText/AseedText';
import { AseedTitle } from '../../../../../AseedTypography/AseedTitle/AseedTitle';
import { useBalance } from '../../../../../Menu/BalanceContext';
import MessageBubble from '../../../../MessageBubble';
import { type SearchScope, SearchSettings } from './SearchSettings';
import { Message, NotEnoughTokensError, assistantService } from 'src/services/assistant.service';

import tabStyles from '../../ReportTabs.module.scss';
import styles from './ReportAssistant.module.scss';

interface ReportAssistantProps {
    reportId: string;
    isTranscriptOnly: boolean;
}

const getPlaceholderByScope = (useInternet: boolean, scope: SearchScope): string => {
    const prefix = useInternet ? '􀆪 ' : ''; // icon 􀆪
    switch (scope) {
        case 'all':
            return `${prefix}Ask any question about the report or transcript...`;
        case 'transcript':
            return `${prefix}Ask a question about the conversation transcript...`;
        case 'report':
            return `${prefix}Ask a question about the analysis report...`;
        default:
            return 'Ask your question...';
    }
};

export const ReportAssistant: React.FC<ReportAssistantProps> = ({ reportId, isTranscriptOnly }) => {
    const getSettingsInitialState = () => {
        return isTranscriptOnly ? 'transcript' : 'all';
    };

    const [messages, setMessages] = useState<Message[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef('');
    const isInitializedRef = useRef(false);
    const [searchScope, setSearchScope] = useState<SearchScope>(getSettingsInitialState());
    const [useInternet, setUseInternet] = useState(false);
    const { refreshBalance } = useBalance();

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleScroll = () => {
        if (!messagesContainerRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
        const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
        setShowScrollButton(!isNearBottom);
    };

    useEffect(() => {
        const container = messagesContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    }, [messages]);

    useEffect(() => {
        const loadChatHistory = async () => {
            if (isInitializedRef.current) return;

            try {
                const history = await assistantService.getChatHistory(reportId);
                if (history.length > 0) {
                    setMessages(history);
                    isInitializedRef.current = true;
                }
            } catch (error) {
                console.error('Failed to load chat history:', error);
            }
        };

        loadChatHistory();
    }, [reportId]);

    const updateMessage = useCallback((chunk: string) => {
        contentRef.current += chunk;
        setMessages(prev => {
            const newMessages = [...prev];
            const lastMessage = newMessages[newMessages.length - 1];
            lastMessage.content = contentRef.current;
            return newMessages;
        });
    }, []);

    const handleSend = async () => {
        if (!inputValue.trim() || isLoading) return;

        const userMessage: Message = {
            role: 'user',
            content: inputValue,
        };

        setMessages(prev => [...prev, userMessage]);
        setInputValue('');
        setIsLoading(true);
        contentRef.current = '';

        const assistantMessage: Message = {
            role: 'assistant',
            content: '',
        };

        setMessages(prev => [...prev, assistantMessage]);

        try {
            await assistantService.streamMessage(
                reportId,
                userMessage.content,
                chunk => {
                    requestAnimationFrame(() => updateMessage(chunk));
                },
                { search_scope: searchScope, use_web: useInternet }
            );
            refreshBalance();
        } catch (error) {
            if (error instanceof NotEnoughTokensError) {
                message.warning('Ooops, Not Enough Tokens');
                setMessages(prev => prev.slice(0, -1));
            }
            console.error('Error streaming message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const EmptyState = () => (
        <div className={styles.emptyState}>
            <Flex gap={6} vertical>
                <AseedTitle level={4} fontWeight={500}>
                    Ask the AI
                </AseedTitle>
                <AseedText description={true}>
                    Got questions about the interview?
                    <br />
                    Let the AI assistant help you find the answers.
                </AseedText>
            </Flex>
        </div>
    );

    return (
        <div className={tabStyles.tabContainer}>
            <div className={tabStyles.tabContent}>
                <div ref={messagesContainerRef} className={styles.messagesContainer}>
                    {messages.length === 0 ? (
                        <EmptyState />
                    ) : (
                        messages.map((message, index) => (
                            <div
                                key={index}
                                style={{
                                    backgroundColor: 'transparent',
                                    padding: '6px',
                                    //borderRadius: '22px',
                                    //transition: 'all 0.5s ease',
                                    marginBottom: '4px',
                                }}
                            >
                                <MessageBubble key={index} isRespondent={message.role === 'assistant'} text={message.content} />
                            </div>
                        ))
                    )}
                    <div ref={messagesEndRef} />
                </div>

                {showScrollButton && (
                    <div className={styles.scrollToBottom} onClick={scrollToBottom}>
                        <DownOutlined />
                    </div>
                )}
            </div>

            <div className={styles.inputContainer}>
                {/* <div className={styles.tokenInfo}>
                    20 free requests available. After that, 1 request = 1 token
                </div> */}
                <div className={styles.inputWrapper}>
                    <Input.TextArea
                        value={inputValue}
                        maxLength={1000}
                        onChange={e => setInputValue(e.target.value)}
                        placeholder={getPlaceholderByScope(useInternet, searchScope)}
                        autoSize={{ minRows: 1, maxRows: 4 }}
                        onPressEnter={e => {
                            if (!e.shiftKey) {
                                e.preventDefault();
                                handleSend();
                            }
                        }}
                    />
                    <div className={styles.searchSettingsContainer}>
                        <SearchSettings
                            isTranscriptOnly={isTranscriptOnly}
                            searchScope={searchScope}
                            useInternet={useInternet}
                            onSearchScopeChange={setSearchScope}
                            onUseInternetChange={setUseInternet}
                        />
                    </div>
                </div>

                <button className={styles.buttonStyle} onClick={handleSend}>
                    <Icon
                        name="arrowUp"
                        width={16}
                        height={16}
                        fill="currentColor"
                        style={{ stroke: 'currentColor', strokeWidth: '0.5px' }}
                    />
                </button>
            </div>
        </div>
    );
};
