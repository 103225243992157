import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spin } from 'antd';

import { PATH } from '../constants/paths';
import { firebaseAuth } from '../services/firebase/AseedFirebase';
import { onAuthStateChanged } from 'firebase/auth';

// Создаем общий хук для обоих компонентов
const useAuthState = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, user => {
            setIsAuthenticated(!!user && user.emailVerified);
        });

        return () => unsubscribe();
    }, []);

    return isAuthenticated;
};

export const PrivateRoute = () => {
    const isAuthenticated = useAuthState();

    if (isAuthenticated === null) {
        return <Spin size="large" />;
    }

    return isAuthenticated ? <Outlet /> : <Navigate to={PATH.HOME} />;
};

export const PublicRoute = () => {
    const isAuthenticated = useAuthState();

    if (isAuthenticated === null) {
        return <Spin size="large" />;
    }

    return isAuthenticated ? <Navigate to={PATH.WORKSPACE} /> : <Outlet />;
};
