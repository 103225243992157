import { PaymentDetailsRequest } from '../@types/profile_payment';
import config from '../config';
import { concatUrl } from './helpers/url-helper';
import axios from 'axios';

const { PROFILE_API_URL } = config;

class ProfilePaymentService {
    prefix = '/api/v1/payments/yookassa';

    async generateToken(details: PaymentDetailsRequest): Promise<string> {
        const url = new URL(concatUrl(PROFILE_API_URL, `${this.prefix}/generate_token`)).toString();

        const payload = {
            IdempotenceKey: details.idempotenceKey,
            Amount: details.amount,
            TokenCount: details.tokenCount,
            Description: details.description,
        };

        const response = await axios.post(url, payload);

        if (response.status !== 200) {
            throw new Error('Failed to create payment token');
        }

        return response.data;
    }
}

export const profilePaymentService = new ProfilePaymentService();
