import React, { useEffect, useRef, useState } from 'react';

import { TranscriptExample } from 'src/@types/report';

import styles from './SourcesTooltip.module.scss';

interface SourcesTooltipProps {
    dialogExamples: TranscriptExample[];
    styles?: React.CSSProperties;
    onSourceClick?: (example: TranscriptExample) => void;
}

export const SourcesTooltip: React.FC<SourcesTooltipProps> = ({ dialogExamples, styles: customStyles, onSourceClick }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState<'top' | 'bottom'>('top');
    const containerRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<number | null>(null);
    const isMouseOverTooltipRef = useRef(false);
    const isMouseOverTriggerRef = useRef(false);

    const handleMouseEnter = () => {
        isMouseOverTriggerRef.current = true;

        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }

        setIsVisible(true);
        updatePosition();
    };

    const handleMouseLeave = () => {
        isMouseOverTriggerRef.current = false;

        // Задержка перед скрытием тултипа, чтобы пользователь успел перевести курсор на тултип
        timeoutRef.current = window.setTimeout(() => {
            if (!isMouseOverTooltipRef.current) {
                setIsVisible(false);
            }
        }, 300);
    };

    const handleTooltipMouseEnter = () => {
        isMouseOverTooltipRef.current = true;

        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    const handleTooltipMouseLeave = () => {
        isMouseOverTooltipRef.current = false;

        // Проверяем, находится ли курсор над триггером
        if (!isMouseOverTriggerRef.current) {
            setIsVisible(false);
        }
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const updatePosition = () => {
        if (!containerRef.current || !tooltipRef.current) return;

        const triggerRect = containerRef.current.getBoundingClientRect();
        const tooltipHeight = tooltipRef.current.offsetHeight;
        const tooltipWidth = tooltipRef.current.offsetWidth;

        // Проверяем, достаточно ли места сверху
        const spaceAbove = triggerRect.top;
        const spaceBelow = window.innerHeight - triggerRect.bottom;

        // Вычисляем позицию по вертикали
        if (spaceAbove < tooltipHeight && spaceBelow >= tooltipHeight) {
            setPosition('bottom');
            tooltipRef.current.style.top = `${triggerRect.bottom + 12}px`;
        } else {
            setPosition('top');
            tooltipRef.current.style.top = `${triggerRect.top - tooltipHeight - 12}px`;
        }

        // Вычисляем позицию по горизонтали
        let leftPosition = triggerRect.left - 10;

        // Проверяем, не выходит ли тултип за пределы экрана
        if (leftPosition < 10) {
            leftPosition = 10; // Минимальный отступ от левого края
        } else if (leftPosition + tooltipWidth > window.innerWidth - 10) {
            leftPosition = window.innerWidth - tooltipWidth - 10; // Минимальный отступ от правого края
        }

        tooltipRef.current.style.left = `${leftPosition}px`;

        // Позиционируем стрелку
        const arrowElement = tooltipRef.current.querySelector(`.${position === 'top' ? styles.topArrow : styles.bottomArrow}`);
        if (arrowElement) {
            const arrowLeft = triggerRect.left + triggerRect.width / 2 - leftPosition - 4;
            (arrowElement as HTMLElement).style.left = `${arrowLeft}px`;
        }
    };

    // Обновляем позицию при скролле и изменении размера окна
    useEffect(() => {
        if (isVisible) {
            const handleUpdate = () => {
                requestAnimationFrame(updatePosition);
            };

            window.addEventListener('resize', handleUpdate);
            window.addEventListener('scroll', handleUpdate, true);

            // Начальное позиционирование
            handleUpdate();

            return () => {
                window.removeEventListener('resize', handleUpdate);
                window.removeEventListener('scroll', handleUpdate, true);
            };
        }
    }, [isVisible]);

    // Очищаем таймер при размонтировании компонента
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                window.clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <div
            className={styles.tooltipContainer}
            ref={containerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <span className={styles.tooltipTrigger} style={customStyles}>
                {dialogExamples.length.toString()}
            </span>

            {isVisible && (
                <div
                    ref={tooltipRef}
                    className={`${styles.tooltipContent} ${styles[position]}`}
                    onMouseEnter={handleTooltipMouseEnter}
                    onMouseLeave={handleTooltipMouseLeave}
                    onClick={handleClick}
                >
                    <div style={{ padding: '0' }}>
                        {dialogExamples.map((dialog, index) => (
                            <button key={index} className={styles.sourceItem} onClick={() => onSourceClick && onSourceClick(dialog)}>
                                <span className={styles.sourceId}>{dialog.id + 1}</span>
                                <span className={styles.sourceText}>{dialog.text}</span>
                            </button>
                        ))}
                    </div>
                    <div className={position === 'top' ? styles.topArrow : styles.bottomArrow} />
                </div>
            )}
        </div>
    );
};
