import React from 'react';

import { Icon } from 'src/components/Common/Icon';

import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { Profile, TranscriptExample } from 'src/@types/report';

import styles from '../../../../renders/RenderDescriptionSection.module.scss';

interface ReportProfileProps {
    profile: Profile;
    expandedKeys: string[];
    setExpandedKeys: (keys: string[]) => void;
    onSourceClick?: (example: TranscriptExample) => void;
}

export const ReportProfile: React.FC<ReportProfileProps> = ({ profile, expandedKeys, setExpandedKeys, onSourceClick }) => {
    const sections = getSections(profile);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            {sections.map(({ id, title, items, iconSymbol }) => (
                <ReportDescriptionSection
                    key={id}
                    id={id}
                    title={title}
                    items={items}
                    iconSymbol={iconSymbol}
                    expandedKeys={expandedKeys}
                    setExpandedKeys={setExpandedKeys}
                    onSourceClick={onSourceClick}
                />
            ))}
        </div>
    );
};

const getSections = (profile: Profile) => {
    return [
        {
            id: 'general-info',
            title: 'General Info',
            items: profile.general_info,
            iconSymbol: <Icon name="personFill" width={17} height={17} fill="currentColor" className={styles.anchorIcon} />,
        },
        {
            id: 'goals',
            title: 'Goals',
            items: profile.goals,
            iconSymbol: (
                <Icon
                    name="mappinEllipse"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-2px)' }}
                />
            ),
        },
        {
            id: 'motivations',
            title: 'Motivations',
            items: profile.motivations,
            iconSymbol: (
                <Icon
                    name="keyFill"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)' }}
                />
            ),
        },
        {
            id: 'pain-points',
            title: 'Pain Points',
            items: profile.pain_points,
            iconSymbol: (
                <Icon
                    name="boltFill"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-2px)' }}
                />
            ),
        },
        {
            id: 'emotions',
            title: 'Emotions',
            items: profile.emotions,
            iconSymbol: (
                <Icon
                    name="heart"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)' }}
                />
            ),
        },
        {
            id: 'tools',
            title: 'Tools',
            items: profile.tools,
            iconSymbol: (
                <Icon
                    name="handPoint"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)' }}
                />
            ),
        },
    ];
};
