import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Layout } from 'antd';

import { ModalPages } from '../components/AuthModal';
import { AuthModal } from '../components/AuthModal';
import { ForgotPassword } from '../components/AuthModal/ForgotPassword';
import { SignIn } from '../components/AuthModal/SignIn';
import { SignUp } from '../components/AuthModal/SignUp';
import commonButtonStyles from '../components/Common/aseedButton.module.scss';
import { ModalContext } from 'src/components/AuthModal/ModalContext';
import { Icon } from 'src/components/Common/Icon';
import { AseedButton } from 'src/components/Common/aseedButton';

import styles from 'src/layouts/StartLayout.module.scss';

const { Content } = Layout;

const StartLayout: React.FC = () => {
    const [inlineAuthPage, setInlineAuthPage] = useState<ModalPages>(ModalPages.SignIn);
    const [modalState, setModalState] = useState<{ modalPage?: ModalPages; isModalOpen: boolean }>({
        modalPage: undefined,
        isModalOpen: false,
    });
    const [email, setEmail] = useState<string>('');
    const [refCode, setRefCode] = useState<string>('');
    const location = useLocation();

    useEffect(() => {
        // Проверяем URL на наличие параметра code
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');

        // Если есть параметр code в URL, устанавливаем его в состояние и открываем окно регистрации
        if (code && location.pathname === '/ref') {
            setRefCode(code);
            setInlineAuthPage(ModalPages.SignUp);
        }
    }, [location]);

    const customSetModalPage: React.Dispatch<React.SetStateAction<ModalPages | undefined>> = pageOrCallback => {
        const page = typeof pageOrCallback === 'function' ? pageOrCallback(inlineAuthPage) : pageOrCallback;
        if (page === ModalPages.ConfirmEmail || page === ModalPages.CheckEmail) {
            setModalState({ modalPage: page, isModalOpen: true });
        } else {
            setInlineAuthPage(page ?? ModalPages.SignIn);
            setModalState({ modalPage: undefined, isModalOpen: false });
        }
    };

    const inlineOnCancel = () => {};

    const handleModalClose = () => {
        setModalState({ modalPage: undefined, isModalOpen: false });
        setInlineAuthPage(ModalPages.SignIn);
    };

    return (
        <Layout className={styles.layoutMinHeight}>
            <Content>
                <div className={styles.mainContainer}>
                    <div className={styles.header}>
                        <div className={styles.headerInner}>
                            <div className={styles.logoContainer}>
                                <Icon name="aseedLogo" height="24px" className="logo" />
                                {/* <div className="logo-text">Insight</div> */}
                            </div>
                        </div>
                    </div>

                    <div className={styles.authContainer}>
                        <ModalContext.Provider value={{ setModalPage: customSetModalPage }}>
                            <div className={styles.authContent}>
                                <div className={styles.authHeader}>
                                    <div className={styles.buttonContainer}>
                                        <AseedButton
                                            text="Insight"
                                            showRightIcon={false}
                                            disableHover={true}
                                            className={commonButtonStyles.aseedButton}
                                        />
                                    </div>
                                    <h1>
                                        Welcome to Aseed
                                        <br />
                                        Your research assistant
                                    </h1>
                                    <p>
                                        Analyze user interviews to uncover deep insights
                                        <br />
                                        and build products users love
                                    </p>
                                </div>
                                <div className={styles.authForm}>
                                    {inlineAuthPage === ModalPages.SignIn && <SignIn setEmail={setEmail} onCancel={inlineOnCancel} />}
                                    {inlineAuthPage === ModalPages.SignUp && (
                                        <SignUp setEmail={setEmail} onCancel={inlineOnCancel} initialRefCode={refCode} />
                                    )}
                                    {inlineAuthPage === ModalPages.ForgotPassword && <ForgotPassword setEmail={setEmail} />}
                                </div>
                            </div>
                            <AuthModal
                                isModalOpen={modalState.isModalOpen}
                                onOk={handleModalClose}
                                onCancel={handleModalClose}
                                page={modalState.modalPage}
                                initialRefCode={refCode}
                            />
                        </ModalContext.Provider>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default StartLayout;
