import { profilePaymentService } from '../../../services/profile.payment.service';

interface YooKassaPaymentOptions {
    onSuccess?: (options: any) => void;
    onFail?: (reason: any, options: any) => void;
    onComplete?: (paymentResult: any, options: any) => void;
}

export class YooKassaPaymentWidget {
    private async loadYooKassaScript(): Promise<void> {
        return new Promise((resolve, reject) => {
            if ((window as any).YooMoneyCheckoutWidget) {
                resolve();
                return;
            }

            const script = document.createElement('script');
            script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
            script.onload = () => resolve();
            script.onerror = () => reject(new Error('Failed to load YooKassa script'));
            document.head.appendChild(script);
        });
    }

    async initiatePayment(
        tokenCount: number,
        amount: number,
        currency: string,
        userId: string,
        email: string,
        callbacks: YooKassaPaymentOptions
    ) {
        try {
            await this.loadYooKassaScript();

            // Генерируем уникальный ключ для идемпотентности
            const idempotenceKey = `${userId}-${Date.now()}`;

            // Получаем токен от YooKassa через наш сервис
            const confirmationToken = await profilePaymentService.generateToken({
                idempotenceKey,
                amount,
                tokenCount,
                description: `Purchase ${tokenCount} tokens`,
            });
            console.log(`Токен конфирмации: ${confirmationToken}`);
            // Инициализируем виджет YooKassa
            const checkout = new (window as any).YooMoneyCheckoutWidget({
                confirmation_token: confirmationToken,
                return_url: `${window.location.origin}/work-space/?payment_status=success`,
                customization: {
                    //Настройка способа отображения
                    modal: true,
                },
                error_callback: (error: any) => {
                    console.error('YooKassa payment error:', error);
                    callbacks.onFail?.(error, { tokenCount, amount });
                },
            });

            // Открываем виджет
            checkout.render();
        } catch (error) {
            console.error('Failed to initiate YooKassa payment:', error);
            callbacks.onFail?.(error, { tokenCount, amount });
        }
    }
}
