export const TEXT_AREA_GOALS = `Product Description:
A mobile banking app designed to make transactions easier, with a recently added feature for instant transfers between accounts.

Research Goal:
I want to understand customer satisfaction with our new mobile app feature, focusing on any challenges they're facing and the emotions they experience.

Additional:
I'm especially interested in any frustrations or positive feelings users have while using this new feature.`;

export const TEXT_AREA_NOTES = `My notes from the interviews:
– Alex (Participant) expressed concerns about the new feature’s complexity.
– He want a simpler user interface.
...
`;
