import React from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

import { BalanceProvider } from '../components/Menu/BalanceContext';
import { ProjectListProvider } from '../components/UploadedFiles/ProjectListContext';
import { UploadFilesProvider } from '../components/UploadedFiles/UploadFilesContext';

import { ReportNotificationProvider } from './ReportNotificationContext';
import { AdminProvider } from 'src/contexts/AdminContext';

import styles from './MainLayout.module.scss';

export const MainLayout: React.FC = () => {
    return (
        <Layout>
            <div className={mainLayout}>
                <AdminProvider>
                    <ReportNotificationProvider>
                        <main className={contentWrapper}>
                            <ProjectListProvider>
                                <UploadFilesProvider>
                                    <Outlet />
                                </UploadFilesProvider>
                            </ProjectListProvider>
                        </main>
                    </ReportNotificationProvider>
                </AdminProvider>
            </div>
        </Layout>
    );
};

const { mainLayout, contentWrapper } = styles;
