import React, { useEffect, useState } from 'react';

import { Affix } from 'antd';

import { Icon } from '../../../Common/Icon';
import { useReportSetupContext } from '../ReportSetupContext';
import { useReportWidgetContext } from '../ReportWidgetContext';
import { useGenerateButtonProps, useGenerateReportApi } from './GenerateReportHook';

import styles from './ReportGenerateMenu.module.scss';

const ReportGenerateMenu: React.FC = () => {
    const [, generateReport] = useGenerateReportApi();
    const { buttonProps, isAdminView } = useGenerateButtonProps();

    const {
        isWithTranscriptState: [isWithTranscript],
        isWithInsightReportState: [isWithInsightReport],
    } = useReportSetupContext();

    const [isAffixed, setIsAffixed] = useState(false);

    const { report } = useReportWidgetContext();

    const [generationPrice, setGenerationPrice] = useState<number>(0);

    useEffect(() => {
        let price = 0;

        if (isWithTranscript) {
            price += report?.expected_tokens.price_with_transcript || 0;
        }
        if (isWithInsightReport) {
            price += report?.expected_tokens.price_with_report || 0;
        }
        setGenerationPrice(price);
    }, [isWithInsightReport, isWithTranscript, report]);

    return (
        <Affix offsetBottom={12} onChange={affixed => setIsAffixed(!!affixed)}>
            <div className={`${styles.menuWrapper} ${isAffixed ? styles.affixed : ''}`}>
                <div className={styles.buttonContainer}>
                    <button className={styles.buttonStyle} onClick={() => generateReport()} disabled={buttonProps.disabled}>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '8px',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Icon name="sparkles" width="22px" height="22px" fill={'white'} />
                                <span>{buttonProps.text}</span>
                            </div>
                            <Icon name="chevronRight" width="16px" height="16px" fill="white" className={styles.chevronRightIcon} />
                        </div>
                    </button>
                    {!isAdminView &&
                        (!buttonProps.disabled ? (
                            <span className={styles.tokenText}>{generationPrice} tokens</span>
                        ) : (
                            <span className={styles.tokenText} style={{ color: '#ff4d4f' }}>
                                Choose at least one type of analysis
                            </span>
                        ))}
                </div>
            </div>
        </Affix>
    );
};

export default ReportGenerateMenu;
