import config from '../../../config';

declare global {
    interface Window {
        tiptop: any;
    }
}

interface PaymentWidgetOptions {
    publicId: string;
    description: string;
    amount: number;
    currency: string;
    skin: string;
    autoClose: number;
    data: {
        AuthId: string;
        Email: string;
        TokenCount: number;
    };
    invoiceId: string;
}

interface PaymentCallbacks {
    onSuccess?: (options: any) => void;
    onFail?: (reason: any, options: any) => void;
    onComplete?: (paymentResult: any, options: any) => void;
}

export class TiptopPaymentWidget {
    private widget: any;

    constructor() {
        this.widget = new window.tiptop.Widget({ language: 'en-US' });
    }

    public initiatePayment(
        tokenCount: number,
        amount: number,
        currency: string,
        userId: string,
        userEmail: string,
        callbacks: PaymentCallbacks
    ) {
        const options: PaymentWidgetOptions = {
            publicId: config.TIPOPPAY_PUBLIC_ID,
            description: `Tokens amount: ${tokenCount}`,
            amount: Math.round(amount),
            currency: currency,
            skin: 'mini',
            autoClose: 3,
            data: {
                AuthId: userId,
                Email: userEmail,
                TokenCount: tokenCount,
            },
            invoiceId: crypto.randomUUID(),
        };

        this.widget.pay('charge', options, callbacks);
    }
}
