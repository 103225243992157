import { useCallback, useState } from 'react';

import { Insights, JTBD, Profile, Recommendations } from '../../../@types/report';
import { getJTBDKeys, getOverviewKeys } from './JobKeys';

export const useJTBDExpandedKeys = (jtbd: JTBD) => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    const toggleExpandAll = useCallback(() => {
        const allKeys = getJTBDKeys(jtbd);
        setExpandedKeys(expandedKeys.length > 0 ? [] : allKeys);
    }, [jtbd, expandedKeys.length]);

    return { expandedKeys, setExpandedKeys, toggleExpandAll };
};

export const useOverviewExpandedKeys = (profile: Profile, insights: Insights, recommendations: Recommendations) => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>(() => getOverviewKeys(profile, insights, recommendations));

    const toggleExpandAll = useCallback(() => {
        setExpandedKeys(expandedKeys.length > 0 ? [] : getOverviewKeys(profile, insights, recommendations));
    }, [profile, insights, recommendations, expandedKeys.length]);

    return { expandedKeys, setExpandedKeys, toggleExpandAll };
};
