import React from 'react';

import { Icon } from 'src/components/Common/Icon';

import { ReportDescriptionSection } from '../../../../renders/RenderDescriptionSection';
import { Insights, TranscriptExample } from 'src/@types/report';

import styles from '../../../../renders/RenderDescriptionSection.module.scss';

interface ReportInsightsProps {
    insights: Insights;
    expandedKeys: string[];
    setExpandedKeys: (keys: string[]) => void;
    onSourceClick?: (example: TranscriptExample) => void;
}

export const ReportInsights: React.FC<ReportInsightsProps> = ({ insights, expandedKeys, setExpandedKeys, onSourceClick }) => {
    const sections = getSections(insights);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            {sections.map(({ id, title, items, iconSymbol }) => (
                <ReportDescriptionSection
                    key={id}
                    id={id}
                    title={title}
                    items={items}
                    iconSymbol={iconSymbol}
                    expandedKeys={expandedKeys}
                    setExpandedKeys={setExpandedKeys}
                    onSourceClick={onSourceClick}
                />
            ))}
        </div>
    );
};

const getSections = (insights: Insights) => {
    return [
        {
            id: 'key-insights',
            title: 'Key Insights',
            items: insights.key_insights,
            iconSymbol: (
                <Icon
                    name="handThumbsup"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-2px)' }}
                />
            ),
        },
        {
            id: 'barriers',
            title: 'Barriers',
            items: insights.barriers,
            iconSymbol: (
                <Icon
                    name="handRaised"
                    width={18}
                    height={18}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-2px)' }}
                />
            ),
        },
        {
            id: 'engagements',
            title: 'Engagements',
            items: insights.engagements,
            iconSymbol: (
                <Icon
                    name="handsClap"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)' }}
                />
            ),
        },
        {
            id: 'expectations',
            title: 'Expectations',
            items: insights.expectations,
            iconSymbol: (
                <Icon
                    name="handPointRight"
                    width={19}
                    height={19}
                    fill="currentColor"
                    className={styles.anchorIcon}
                    style={{ transform: 'translateY(-1px)' }}
                />
            ),
        },
    ];
};
