import React, { useState } from 'react';

import { ReactComponent as ReferralLinkCopiedIcon } from 'src/assets/images/referal/referral-icon-link-copied.svg';
import { ReactComponent as ReferralLinkHoveredIcon } from 'src/assets/images/referal/referral-icon-link-hovered.svg';
import { ReactComponent as ReferralLinkIcon } from 'src/assets/images/referal/referral-icon-link.svg';
import { ReactComponent as ReferralTitleIcon } from 'src/assets/images/referal/referral-icon-title.svg';

import { ReferralService } from '../../services/referral/ReferralService';

import styles from './Referral.module.scss';

const Referral: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const handleMouseEnter = () => {
        setIsExpanded(true);
    };

    const handleMouseLeave = () => {
        setIsExpanded(false);
        setIsCopied(false);
    };

    const handleCopyClick = () => {
        ReferralService.copyReferralLink().then(() => {
            setIsCopied(true);
        });
    };

    return (
        <div
            className={`${styles.referralContainer} ${isExpanded ? styles.expanded : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className={styles.titleContainer}>
                <ReferralTitleIcon className={styles.titleIcon} />
                <span className={styles.title}>Get Extra Tokens</span>
            </div>

            {isExpanded && (
                <p className={styles.description}>
                    Share your unique link and&nbsp;get <strong>extra 300 tokens</strong> for&nbsp;each friend who signs up.
                </p>
            )}

            <button
                className={`${styles.copyButton} ${isExpanded ? styles.expanded : ''} ${isCopied ? styles.copied : ''}`}
                onClick={handleCopyClick}
            >
                <div className={styles.buttonContent}>
                    {isCopied ? <ReferralLinkCopiedIcon /> : isExpanded ? <ReferralLinkHoveredIcon /> : <ReferralLinkIcon />}
                    <span>{isCopied ? 'Invite Link Copied!' : 'Copy Invite Link'}</span>
                </div>
                {isExpanded && (
                    <div className={`${styles.codeText} ${isCopied ? styles.copied : ''}`}>Your code: {ReferralService.getRefCode()}</div>
                )}
            </button>
        </div>
    );
};

export default Referral;
