import {
    DetailedReportResponse,
    IGenerateRecordRequest,
    IReGenerateRecordRequest,
    IUploadFileRequest,
    RecordResponse,
    UpdateRecordsRequest,
} from '../@types/jtbd';
import config from '../config';
import { concatUrl } from './helpers/url-helper';
import axios from 'axios';

const { CUSTDEV_API_URL } = config;

class JTBDService {
    async uploadAudio(request: IUploadFileRequest): Promise<string> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, '/jtbd/upload/')).toString();
        const response = await axios.post(url, request);

        if (response.status === 200) {
            return response.data;
        }

        throw new Error('Failed to upload audio file');
    }

    async checkSpace(fileSizeInMb: number, signal: AbortSignal): Promise<boolean> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, '/jtbd/check_space/')).toString();
        const response = await axios.post(url, { file_size_in_mb: fileSizeInMb }, { signal });

        if (response.status === 200) {
            return response.data.is_enough === 'True';
        }

        throw new Error('Failed to check available space');
    }

    async generateReport(id: string, request: IGenerateRecordRequest): Promise<void> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, `/jtbd/${id}/generate/`)).toString();
        const response = await axios.post(url, request);

        if (response.status !== 204) {
            throw new Error('Failed to generate report');
        }
    }

    async reGenerateReport(id: string, request: IReGenerateRecordRequest): Promise<void> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, `/jtbd/${id}/regenerate/`)).toString();
        const response = await axios.post(url, request);

        if (response.status !== 204) {
            throw new Error('Failed to re-generate report');
        }
    }

    async updateRecords(request: UpdateRecordsRequest): Promise<void> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, '/jtbd/update/')).toString();
        const response = await axios.put(url, request);

        if (response && response.status !== 204) {
            throw new Error('Failed to update records');
        }
    }

    async getRecords(): Promise<RecordResponse> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, '/jtbd/')).toString();
        const response = await axios.get(url);

        if (response.status === 200) {
            return response.data;
        }

        throw new Error('Failed to fetch records');
    }

    async getDetailedReport(id: string, version?: string): Promise<DetailedReportResponse> {
        let url = new URL(concatUrl(CUSTDEV_API_URL, `/jtbd/${id}/`)).toString();

        if (version && version !== 'Last') {
            url = `${url}?version=${version}`;
        }

        const response = await axios.get(url);
        if (response.status === 200) {
            if (response.data.status === 'Uploaded') {
                return {
                    ...response.data,
                    setup: DEFAULT_SETUP,
                };
            }
            return response.data;
        }

        throw new Error('Failed to fetch detailed report');
    }
}

const jtbdService = new JTBDService();
export default jtbdService;

const DEFAULT_SETUP = {
    language: null,
    report_language: null,
    participants_count: null,
    respondent_known_names: [],
    is_with_insight_report: true,
    is_with_transcript: true,
    goals: null,
};

export const INTRO_REPORT_ID = '00000000-0000-0000-0000-000000000000';
