import React, { createContext, useContext, useEffect, useState } from 'react';

import { checkIsAdmin, firebaseAuth } from 'src/services/firebase/AseedFirebase';

type AdminContextType = {
    isAdmin: boolean;
};

const AdminContext = createContext<AdminContextType>({ isAdmin: false });

export const AdminProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const checkAdminStatus = async () => {
            const user = firebaseAuth.currentUser;
            if (user) {
                const admin = await checkIsAdmin(user);
                setIsAdmin(admin);
            }
        };
        checkAdminStatus();
    }, []);

    return <AdminContext.Provider value={{ isAdmin }}>{children}</AdminContext.Provider>;
};

export const useAdmin = () => {
    const context = useContext(AdminContext);
    if (context === undefined) {
        throw new Error('useAdmin must be used within an AdminProvider');
    }
    return context;
};
