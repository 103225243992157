import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { Modal, message } from 'antd';

import { useProjectListContext } from './ProjectListContext';
import { RcFile } from 'antd/es/upload';
import { CanceledError } from 'axios';
import { UploadMetadata, UploadTaskSnapshot, getMetadata, ref, uploadBytesResumable } from 'firebase/storage';
import { firebaseAuth, firebaseStorage } from 'src/services/firebase/AseedFirebase';
import jtbdService from 'src/services/jtbd.service';
import { v4 as uuidv4 } from 'uuid';

export interface UploadFileTask {
    fileId: string;
    name: string;
    abortController: AbortController;
    uploadTask?: UploadTaskSnapshot;
}

type UploadFilesContext = {
    uploadNewFile: (file: RcFile) => Promise<string>;
    uploadFileTasks: Map<string, UploadFileTask>;
    removeTask: (fileId: string) => void;
    addUploadedFile: (file: UploadFileTask) => void;
};

const UploadFilesCtx = createContext<UploadFilesContext>({
    uploadNewFile: async () => '',
    uploadFileTasks: new Map(),
    removeTask: () => {},
    addUploadedFile: () => {},
});

export const UploadFilesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { fetchProjectList } = useProjectListContext();
    const [uploadFileTasks, setUploadFileTasks] = useState<Map<string, UploadFileTask>>(new Map());

    const removeTask = useCallback((fileId: string) => {
        setUploadFileTasks(prev => {
            const next = new Map(prev);
            next.delete(fileId);
            return next;
        });
    }, []);

    const uploadNewFile = useCallback(
        async (file: RcFile) => {
            const fileId = uuidv4();
            const abortController = new AbortController();
            const task = {
                fileId,
                name: removeFileExtension(file.name),
                abortController,
            };

            setUploadFileTasks(prev => new Map(prev).set(fileId, task));

            try {
                await checkSpaceForUploading(file, abortController.signal);
                const duration = await getAudioDuration(file, abortController.signal);
                const metadata: UploadMetadata = {
                    customMetadata: {
                        duration: duration.toString(),
                        name: removeFileExtension(file.name),
                        fileId,
                    },
                };

                if (!firebaseAuth.currentUser?.emailVerified) {
                    throw new Error('User verification error');
                }
                const storageRef = ref(firebaseStorage, `audio/${firebaseAuth.currentUser.uid}/${fileId}/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);
                setUploadFileTasks(prev =>
                    new Map(prev).set(fileId, {
                        ...task,
                        uploadTask: uploadTask.snapshot,
                    })
                );

                uploadTask.on(
                    'state_changed',
                    null,
                    error => {
                        console.error('Upload error:', error);
                        if (error.code === 'storage/unauthorized') {
                            message.error('Authorization error or wrong file type');
                        } else if (error.code === 'storage/canceled') {
                            abortController.abort();
                            console.info('Upload canceled');
                        } else {
                            message.error('Error uploading file');
                        }
                        removeTask(fileId);
                    },
                    async () => {
                        const metadata = await getMetadata(storageRef);
                        try {
                            await jtbdService.uploadAudio({ record_name: removeFileExtension(file.name), file_path: metadata.fullPath });
                        } catch (error) {
                            console.error('Upload error:', error);
                        }
                        removeTask(fileId);
                        fetchProjectList();
                    }
                );
            } catch (error) {
                if (error instanceof Error) {
                    message.error(error.message);
                } else {
                    console.error('Upload error:', error);
                }
                removeTask(fileId);
            } finally {
                return fileId;
            }
        },
        [fetchProjectList, removeTask]
    );

    const addUploadedFile = (file: UploadFileTask) => {
        setUploadFileTasks(prev => new Map(prev).set(file.fileId, file));
    };

    const values = useMemo(
        () => ({
            uploadNewFile,
            uploadFileTasks,
            removeTask,
            addUploadedFile,
        }),
        [uploadNewFile, uploadFileTasks, removeTask, addUploadedFile]
    );

    return <UploadFilesCtx.Provider value={values}>{children}</UploadFilesCtx.Provider>;
};

export const useUploadFileContext = (): UploadFilesContext => {
    const context = useContext(UploadFilesCtx);
    if (context === undefined) {
        throw new Error('useUploadFileContext must be used within a UploadFilesContext');
    }
    return context;
};

const checkSpaceForUploading = async (file: RcFile, abortSignal: AbortSignal): Promise<void> => {
    try {
        const fileSizeInMb = file.size / (1024 * 1024);
        const isEnoughSpace = await jtbdService.checkSpace(fileSizeInMb, abortSignal);
        if (!isEnoughSpace) {
            throw new Error('Not enough space');
        }
    } catch (error) {
        if (error instanceof CanceledError) {
            return;
        }
        Modal.error({
            title: 'Not enough space',
            content: 'You do not have enough space to upload this file. Please free up space by deleting old records.',
            okText: 'OK',
        });
        throw error;
    }
};

const removeFileExtension = (fileName: string): string => {
    return fileName.replace(/\.[^/.]+$/, '');
};

const getAudioDuration = async (file: RcFile, abortSignal: AbortSignal): Promise<number> => {
    const extension = file.name.split('.').pop()?.toLowerCase();

    if (extension === 'amr') {
        const fileSizeInBits = file.size * 8;
        const bitRate = 12200; // 12.2 кбит/с для AMR-NB
        return fileSizeInBits / bitRate;
    }

    try {
        return await getFallbackDuration(file, abortSignal);
    } catch (fallbackError) {
        console.warn('Fallback метод не сработал, используем расчет по размеру файла');
        return calculateDurationFromFileSize(file);
    }
};

// Выделяем fallback логику в отдельную функцию
const getFallbackDuration = (file: RcFile, abortSignal: AbortSignal): Promise<number> => {
    return new Promise((resolve, reject) => {
        const media = document.createElement(file.type.startsWith('video/') ? 'video' : 'audio');
        const objectUrl = URL.createObjectURL(file);

        const cleanup = () => {
            URL.revokeObjectURL(objectUrl);
            media.remove();
        };

        media.addEventListener('loadedmetadata', () => {
            const duration = media.duration;
            cleanup();
            resolve(duration);
        });

        media.addEventListener('error', () => {
            cleanup();
            reject(new Error('Не удалось загрузить метаданные'));
        });

        abortSignal.addEventListener('abort', () => {
            cleanup();
            reject(new Error('Операция отменена'));
        });

        media.src = objectUrl;
    });
};

// Добавляем функцию для расчета длительности на основе размера файла
const calculateDurationFromFileSize = (file: RcFile): number => {
    const extension = file.name.split('.').pop()?.toLowerCase();
    const fileSizeInBits = file.size * 8;

    // Приблизительные битрейты для разных форматов (в битах в секунду)
    const bitRates: Record<string, number> = {
        mp3: 320000, // ~320 кбит/с
        aac: 256000, // ~256 кбит/с
        wav: 1411000, // ~1411 кбит/с (CD качество)
        flac: 900000, // ~900 кбит/с
        ogg: 192000, // ~192 кбит/с
        webm: 500000, // ~500 кбит/с (аудио)
        m4a: 256000, // ~256 кбит/с
        mp4: 2000000, // ~2 Мбит/с (видео)
        mov: 2500000, // ~2.5 Мбит/с (видео)
        m4v: 2000000, // ~2 Мбит/с (видео)
        mpg: 1500000, // ~1.5 Мбит/с (видео)
        mp2: 256000, // ~256 кбит/с
        oga: 192000, // ~192 кбит/с
        aiff: 1411000, // ~1411 кбит/с
        aif: 1411000, // ~1411 кбит/с
        midi: 15000, // ~15 кбит/с
        mpga: 320000, // ~320 кбит/с
        au: 88000, // ~88 кбит/с
        ogm: 500000, // ~500 кбит/с
    };

    // Используем указанный битрейт или средний битрейт аудио, если формат неизвестен
    const bitRate = extension && bitRates[extension] ? bitRates[extension] : 256000;

    // Расчет длительности в секундах
    const durationInSeconds = fileSizeInBits / bitRate;

    console.log(
        `Расчетная длительность для файла ${file.name}: ${durationInSeconds} секунд (размер: ${file.size} байт, битрейт: ${bitRate} бит/с)`
    );

    return durationInSeconds;
};
