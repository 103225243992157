import React from 'react';
import styles from './aseedButton.module.scss';
import { Icon } from './Icon';

interface AseedButtonProps {
  /** Текст, который будет отображаться на кнопке */
  text: string;
  /** Обработчик клика */
  onClick?: () => void;
  /** Состояние неактивной (заблокированной) кнопки */
  disabled?: boolean;
  /** Дополнительный класс для кастомизации */
  className?: string;
  /** Показывать ли правую иконку (chevron) при ховере, по умолчанию true */
  showRightIcon?: boolean;
  /** Отключить hover-эффект и изменить курсор на обычную стрелку */
  disableHover?: boolean;
}

export const AseedButton: React.FC<AseedButtonProps> = ({
  text,
  onClick,
  disabled = false,
  className = '',
  showRightIcon = true,
  disableHover = false,
}) => {
  return (
    <button
      type="button"
      className={`${styles.aseedButton} ${className} ${!showRightIcon ? styles.noRightIcon : styles.RightIcon} ${disableHover ? styles.aseedButtonNoHover : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.buttonContent}>
        <div className={styles.leftIcon}>
          <Icon name="aseedIcon" width="17px" height="17px" fill="white" />
          <span className={styles.buttonText}>{text}</span>
        </div>
        {showRightIcon && (
          <Icon
            name="chevronRight"
            width="16px"
            height="16px"
            fill="white"
            className={styles.chevronRightIcon}
          />
        )}
      </div>
    </button>
  );
};