import React, { useState } from 'react';

import { DynamicInfofacts, useIsMobile } from 'src/components/Common/DynamicInfo';
import Informer from 'src/components/Common/Informer';

import { infofacts } from 'src/constants/infofacts';

export const ErrorTabContent: React.FC = () => {
    const [errorInformerHovered, setErrorInformerHovered] = useState(false);
    const isMobile = useIsMobile();
    const containerPaddingTop = isMobile ? 0 : 100; // динамический отступ сверху

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                maxWidth: '600px',
                margin: '0 auto',
                paddingTop: 0,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: containerPaddingTop,
                    left: 0,
                    right: 0,
                    margin: '0 auto',
                    width: '100%',
                    maxWidth: '600px',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
            >
                <div className="spinning">
                    <Informer
                        text={
                            <>
                                <strong>Something went wrong</strong>
                                <p>We encountered an issue while loading your data, but we're already on it.</p>
                                <strong>What Can Be Done:</strong>
                                <ul>
                                    <li>
                                        <strong>Wait and Try Again Later:</strong> We are already working on the problem and will fix it
                                        soon.
                                    </li>
                                    <li>
                                        <strong>Contact Us:</strong> If the problem persists, reach out to us on
                                        <a
                                            href="https://t.me/viktorbondarovich"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: '#39a0ff' }}
                                        >
                                            &nbsp;👉🏻 Telegram
                                        </a>
                                        .
                                    </li>
                                </ul>
                                <p>Thank you for your understanding. We apologize for any inconvenience.</p>
                            </>
                        }
                        showIcon
                        iconName="xmarkFill"
                        style={{
                            paddingBottom: '10px',
                            background: '#ff565610',
                            border: '1px solid rgb(255, 227, 227)',
                            borderRadius: 16,
                        }}
                        textStyle={{ color: '#CD1621' }}
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        minHeight: '200px',
                        cursor: errorInformerHovered ? 'help' : 'default',
                    }}
                    onMouseEnter={() => setErrorInformerHovered(true)}
                    onMouseLeave={() => setErrorInformerHovered(false)}
                >
                    <Informer
                        text={
                            <>
                                <strong>did you know ...</strong>
                                <DynamicInfofacts />
                            </>
                        }
                        showIcon
                        iconName="bulb"
                        style={{
                            paddingBottom: '10px',
                            background: '#B0F4D620',
                            backdropFilter: 'blur(20px)',
                            border: errorInformerHovered ? '1px solid rgb(82, 220, 174)' : '1px solid #C7F0E2',
                            borderRadius: 16,
                        }}
                        textStyle={{ color: '#097B5D' }}
                    />
                </div>
            </div>
        </div>
    );
};
