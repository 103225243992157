import { Flex } from 'antd';

import { AseedText } from '../AseedTypography/AseedText/AseedText';

import styles from './MessageBubble.module.scss';

interface MessageBubbleProps {
    isRespondent: boolean;
    knownName?: string;
    text: string;
    line?: number;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ isRespondent, knownName, text, line = undefined }) => {
    return (
        <div className={`${styles.messageBubbleWrapper} ${isRespondent ? styles.respondent : ''}`}>
            <div className={`${styles.messageBubble} ${isRespondent ? styles.respondent : ''}`}>
                {knownName && (
                    <div className={styles.headerContainer}>
                        <div className={styles.nameContainer}>
                            <AseedText active>{knownName}</AseedText>
                        </div>

                        {line && (
                            <div className={styles.lineContainer}>
                                <span>{line}</span>
                            </div>
                        )}
                    </div>
                )}
                <div style={{ marginTop: '6px' }}>
                    <AseedText description styles={{ whiteSpace: 'pre-wrap' }}>
                        {text}
                    </AseedText>
                </div>
            </div>
        </div>
    );
};

export default MessageBubble;
