import React, { useContext } from 'react';

import { SmileOutlined } from '@ant-design/icons';
import { Divider, Flex, Result, theme } from 'antd';

import { ModalContext } from './ModalContext';
import { ModalPages } from './index';

interface ConfirmEmailProps {
    email: string;
}

export const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ email }) => {
    const {
        token: { colorPrimary, margin, marginXS, fontSizeLG, colorWhite, colorError },
    } = theme.useToken();

    const { setModalPage } = useContext(ModalContext);

    return (
        <Flex justify="center" wrap>
            <Result
                icon={<SmileOutlined />}
                title="Great, you have created an Account!"
                extra={
                    <a href={`https://${email.split('@')[1]}`} target="_blank" style={{ fontSize: fontSizeLG + 2 }}>
                        Confirm your e-mail
                    </a>
                }
            />

            <Divider style={{ borderColor: colorWhite, marginTop: 0, color: colorError }} />

            <Flex justify="center" style={{ fontSize: fontSizeLG + 2, marginBottom: margin }}>
                <div style={{ color: '#6A6A70', marginRight: marginXS }}>Do you have an Account?</div>
                <div style={{ color: colorPrimary, cursor: 'pointer' }} onClick={() => setModalPage(ModalPages.SignIn)}>
                    Sign In
                </div>
            </Flex>
        </Flex>
    );
};
