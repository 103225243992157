import { AdminRecordResponse, IAdminResetRecordRequest, IReGenerateRecordRequest, RecordResponse } from '../@types/jtbd';
import config from '../config';
import { concatUrl } from './helpers/url-helper';
import axios from 'axios';

const { CUSTDEV_API_URL } = config;

class JTBDAdminService {
    async getRecords(): Promise<AdminRecordResponse> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, '/admin/records/')).toString();
        const response = await axios.get(url);

        if (response.status === 200) {
            return response.data;
        }

        throw new Error('Failed to fetch records');
    }

    async resetReport(id: string, request: IAdminResetRecordRequest): Promise<void> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, `/admin/regenerate/${id}/`)).toString();
        const response = await axios.post(url, request);

        if (response.status !== 204) {
            throw new Error('Failed to re-generate report');
        }
    }
}

const jtbdAdminService = new JTBDAdminService();
export default jtbdAdminService;
