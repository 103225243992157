import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'src/components/Common/Icon';
import AseedMenu from 'src/components/Menu/Menu';

import { DetailedReportResponse } from '../../../@types/jtbd';
import { EditableTitle } from '../../Common/EditableTitle';
import ReportExport from './ReportExport';
import { ReportVersion } from './ReportVersion';
import { useReportWidgetContext } from './ReportWidgetContext';
import { PATH } from 'src/constants/paths';

import styles from './ReportHeader.module.scss';

const ReportHeader: React.FC<{ report: DetailedReportResponse }> = ({ report }) => {
    const { updateName } = useReportWidgetContext();

    const [name, setName] = useState<string>(report.name);

    const onChangeName = (newName: string) => {
        if (!newName) return;
        setName(newName);
        updateName(newName);
    };

    return (
        <div className={styles.headerLayout}>
            <div className={styles.headerContainer}>
                <Link className={styles.linkBack} to={PATH.WORKSPACE}>
                    <Icon name="chevronLeft" className={styles.linkBackIcon} />
                </Link>
                <EditableTitle value={name} onChange={onChangeName} isEditable={true} className={styles.projectTitle} />
                <div className={styles.rightControls}>
                    <ReportVersion report={report} />
                    <ReportExport className={styles.exportBtn} report={report} />
                </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
                <AseedMenu />
            </div>
        </div>
    );
};

export default ReportHeader;
