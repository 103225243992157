import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Flex, theme } from 'antd';

import { DynamicInfofacts, useIsMobile } from 'src/components/Common/DynamicInfo';
import Informer from 'src/components/Common/Informer';

import { AseedText } from '../../../../AseedTypography/AseedText/AseedText';
import { AseedTitle } from '../../../../AseedTypography/AseedTitle/AseedTitle';
import { PointMaterial, Points } from '@react-three/drei';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { random } from 'maath';
import { infofacts } from 'src/constants/infofacts';
import * as THREE from 'three';
import { ImprovedNoise } from 'three/examples/jsm/math/ImprovedNoise';

function ParticlesSphere({ mouseInside }: { mouseInside: boolean }): JSX.Element {
    const groupRef = useRef<THREE.Group>(null);
    const [sphere] = useState(() => new Float32Array(random.inSphere(new Float32Array(80000), { radius: 1 })));
    const originalSphere = useRef(new Float32Array(sphere));
    const ref = useRef<THREE.Points>(null);
    const { viewport, mouse } = useThree();
    const noise = useRef(new ImprovedNoise()).current;
    const currentSpeed = useRef(0.3);
    const currentAmplitude = useRef(0.7);
    const insideSpeed = 0.6,
        outsideSpeed = 0.3;
    const insideAmplitude = 0.3,
        outsideAmplitude = 0.7;
    const smoothingFactor = 0.5;

    const [colors] = useState(() => {
        const count = sphere.length / 3;
        const arr = new Float32Array(count * 3);
        const color1 = new THREE.Color('#1480E6');
        const color2 = new THREE.Color('#CDF9FF80');
        const color3 = new THREE.Color('##1480E6');
        for (let i = 0; i < count; i++) {
            let color: THREE.Color;
            if (i % 3 === 0) {
                color = color1;
            } else if (i % 3 === 1) {
                color = color2;
            } else {
                color = color3;
            }
            arr[i * 3] = color.r;
            arr[i * 3 + 1] = color.g;
            arr[i * 3 + 2] = color.b;
        }
        return arr;
    });

    useEffect(() => {
        if (ref.current) {
            ref.current.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
        }
    }, [colors]);

    useFrame((state, delta) => {
        if (!ref.current) return;
        ref.current.rotation.x -= delta / 10;
        ref.current.rotation.y -= delta / 15;

        const desiredSpeed = mouseInside ? insideSpeed : outsideSpeed;
        const desiredAmplitude = mouseInside ? insideAmplitude : outsideAmplitude;
        currentSpeed.current = THREE.MathUtils.lerp(currentSpeed.current, desiredSpeed, delta * smoothingFactor);
        currentAmplitude.current = THREE.MathUtils.lerp(currentAmplitude.current, desiredAmplitude, delta * smoothingFactor);

        const time = state.clock.getElapsedTime();
        const posArray = ref.current.geometry.attributes.position.array as Float32Array;
        const count = posArray.length / 3;
        for (let i = 0; i < count; i++) {
            const ix = i * 3;
            const origX = originalSphere.current[ix];
            const origY = originalSphere.current[ix + 1];
            const origZ = originalSphere.current[ix + 2];

            const nX = noise.noise(origX + time * currentSpeed.current, origY, origZ);
            const nY = noise.noise(origX + time * currentSpeed.current + 100, origY + 100, origZ + 100);
            const nZ = noise.noise(origX + time * currentSpeed.current + 200, origY + 200, origZ + 200);

            posArray[ix] = origX + nX * currentAmplitude.current;
            posArray[ix + 1] = origY + nY * currentAmplitude.current;
            posArray[ix + 2] = origZ + nZ * currentAmplitude.current;
        }
        ref.current.geometry.attributes.position.needsUpdate = true;

        if (groupRef.current) {
            const targetX = mouse.x * (viewport.width / 2);
            const targetY = mouse.y * (viewport.height / 2);
            groupRef.current.position.lerp(new THREE.Vector3(targetX, targetY, 0), delta * 0.05);
        }
    });

    return (
        <group ref={groupRef} rotation={[0, 0, Math.PI / 4]}>
            <Points ref={ref} positions={sphere} stride={3} frustumCulled={false}>
                <PointMaterial
                    transparent
                    vertexColors={true}
                    color="#ffffff"
                    size={0.02}
                    sizeAttenuation={true}
                    depthWrite={false}
                    blending={THREE.AdditiveBlending}
                />
            </Points>
        </group>
    );
}

export const LoadingContent: React.FC = () => {
    const [reportInformerHovered, setReportInformerHovered] = useState(false);
    const isMobile = useIsMobile();

    const {
        token: { colorPrimary },
    } = theme.useToken();
    const [seconds, setSeconds] = useState(0);
    const [mouseInside, setMouseInside] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prev => prev + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const formatTime = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const secs = totalSeconds % 60;
        if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ${secs} second${secs !== 1 ? 's' : ''}`;
        } else {
            return `${secs} second${secs !== 1 ? 's' : ''}`;
        }
    };

    const infoContainerTop = isMobile ? 0 : 0;
    const containerPaddingTop = isMobile ? 0 : -100;

    return (
        <Flex
            justify="center"
            align="center"
            style={{
                paddingTop: containerPaddingTop,
                width: '100%',
                height: '100%',
                overflow: 'scroll',
            }}
        >
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <Canvas
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 0,
                    }}
                    camera={{ position: [0, 0, 3], fov: 75, near: 0.1, far: 1000 }}
                    onPointerEnter={() => setMouseInside(true)}
                    onPointerLeave={() => setMouseInside(false)}
                >
                    <ambientLight intensity={0.5} />
                    {/*<ParticlesSphere mouseInside={mouseInside} />*/}
                </Canvas>
                <div
                    style={{
                        position: 'absolute',
                        // paddingTop: containerPaddingTop,
                        top: containerPaddingTop,
                        left: 0,
                        right: 0,
                        margin: '0 auto',
                        width: '100%',
                        height: '100%',
                        maxWidth: isMobile ? undefined : '600px',
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <div className="spinning">
                        <Informer
                            text={
                                <>
                                    <strong>Your Report is being generated for:</strong> {formatTime(seconds)}
                                    <p>
                                        We're processing your transcript and extracting key insights. Depending on the length of your
                                        interview, this may take anywhere from <strong>1 to 10 minutes</strong>.
                                    </p>
                                    <ul>
                                        <li>
                                            Explore your <strong>previous reports</strong> to review past insights.
                                        </li>
                                        <li>
                                            <strong>Upload new files</strong> to queue up additional analyses.
                                        </li>
                                        <li>Grab a coffee – we'll notify you as soon as your report is ready!</li>
                                    </ul>
                                    <p>
                                        Thanks for your patience! Aseed is working to deliver the most accurate and actionable insights for
                                        you.
                                    </p>
                                </>
                            }
                            showIcon={true}
                            iconName="aseedIcon"
                            style={{
                                paddingBottom: '10px',
                                background: '#BBDEFF20',
                                backdropFilter: 'blur(10px)',
                                borderRadius: 16,
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                            minHeight: '200px',
                            cursor: reportInformerHovered ? 'help' : 'default',
                        }}
                        onMouseEnter={() => setReportInformerHovered(true)}
                        onMouseLeave={() => setReportInformerHovered(false)}
                    >
                        <Informer
                            text={
                                <>
                                    <strong>did you know ...</strong>
                                    <DynamicInfofacts />
                                </>
                            }
                            showIcon={true}
                            iconName="bulb"
                            style={{
                                paddingBottom: '10px',
                                background: '#B0F4D620',
                                backdropFilter: 'blur(10px)',
                                border: reportInformerHovered ? '1px solid rgb(82, 220, 174)' : '1px solid #C7F0E2',
                                borderRadius: 16,
                            }}
                            textStyle={{ color: '#097B5D' }}
                        />
                    </div>
                </div>
            </div>
        </Flex>
    );
};

export default LoadingContent;
