import React from 'react';

import ReportHeader from './ReportHeader';
import ReportTabs from './ReportTabController/ReportTabs';
import { ReportTabsProvider } from './ReportTabController/ReportTabsContext';
import { useReportWidgetContext } from './ReportWidgetContext';

import './ReportWidget.scss';

const ReportWidget: React.FC = () => {
    const { isLoadingReport, report, reportId } = useReportWidgetContext();

    return (
        <div className="report-widget" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {report && (
                <div className="report-widget__container">
                    <ReportHeader report={report} />
                    <div className="report-widget__content">
                        <div className={`report-widget__card ${isLoadingReport ? 'loading' : ''}`}>
                            <ReportTabsProvider>
                                <ReportTabs report={report} reportId={reportId} />
                            </ReportTabsProvider>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReportWidget;
