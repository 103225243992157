import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { message } from 'antd';

import { trackEvent } from '../../../../services/firebase/Analytics';
import jtbdAdminService from '../../../../services/jtbd_admin.service';
import { useBalance } from '../../../Menu/BalanceContext';
import { useReportSetupContext } from '../ReportSetupContext';
import { useReportWidgetContext } from '../ReportWidgetContext';
import { ReportStatus } from 'src/@types/enums';
import jtbdService from 'src/services/jtbd.service';

const TEXT_BUTTON_RESET_REPORT = 'Reset Report';

export const useGenerateReportApi = (): [{ isLoading: boolean; error: unknown }, () => Promise<void>] => {
    const { reportState, isWithInsightReportState, isWithTranscriptState } = useReportSetupContext();
    const [searchParams] = useSearchParams();
    const isAdminView = searchParams.get('source') === 'admin';

    const [isWithInsightReport] = isWithInsightReportState;
    const [isWithTranscript] = isWithTranscriptState;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<unknown>();

    const { reportId, report, reloadReport } = useReportWidgetContext();
    const { refreshBalance } = useBalance();

    const fetchData = useCallback(async () => {
        setError(null);
        setIsLoading(true);

        if (!reportId) {
            return console.log('Record id is undefined. Report can not be generated');
        }
        try {
            if (isAdminView) {
                await jtbdAdminService.resetReport(reportId, {
                    ...reportState,
                    is_with_insight_report: isWithInsightReport,
                    is_with_transcript: isWithTranscript,
                });
            } else {
                switch (report?.status) {
                    case ReportStatus.Uploaded:
                        trackEvent('button_click', { button_name: 'generate_report', type: 'generation' });
                        await jtbdService.generateReport(reportId, {
                            ...reportState,
                            is_with_insight_report: isWithInsightReport,
                        });
                        break;
                    case ReportStatus.Finished:
                        trackEvent('button_click', { button_name: 'generate_report', type: 'regeneration' });
                        await jtbdService.reGenerateReport(reportId, {
                            ...reportState,
                            is_with_insight_report: isWithInsightReport,
                            is_with_transcript: isWithTranscript,
                        });
                        break;

                    // case ReportStatus.Error: TODO непонятно что делать в этом статусе
                    default:
                        throw new Error('The report status is wrong');
                }
            }
            await refreshBalance();
        } catch (error) {
            message.warning('Failed to process report');
            setError(error);
        } finally {
            setIsLoading(false);
            reloadReport();
        }
    }, [isWithInsightReport, isWithTranscript, reloadReport, report?.status, reportId, reportState, refreshBalance, isAdminView]);

    return [{ isLoading, error }, fetchData];
};

interface GenerateButtonProps {
    disabled: boolean;
    text: string;
    loading: boolean;
}

const TEXT_BUTTON_GENERATE_REPORT = 'Generate Report';
const TEXT_BUTTON_REGENERATE_REPORT = 'Re-Generate Report';
const TEXT_BUTTON_REGENERATING_REPORT = 'Generating Report';
const TEXT_BUTTON_GENERATE_TRANSCRIPT = 'Generate Transcript';
const TEXT_BUTTON_REGENERATE_TRANSCRIPT = 'Re-Generate Transcript';

export const useGenerateButtonProps = () => {
    const { isWithInsightReportState, isWithTranscriptState } = useReportSetupContext();
    const [searchParams] = useSearchParams();
    const isAdminView = searchParams.get('source') === 'admin';

    const { report } = useReportWidgetContext();
    const [isWithInsightReport] = isWithInsightReportState;
    const [isWithTranscript] = isWithTranscriptState;

    const [buttonProps, setButtonProps] = useState<GenerateButtonProps>({
        disabled: true,
        text: TEXT_BUTTON_REGENERATING_REPORT,
        loading: true,
    });

    useEffect(() => {
        if (isAdminView) {
            setButtonProps({
                disabled: false,
                text: TEXT_BUTTON_RESET_REPORT,
                loading: false,
            });
            return;
        }

        if (report?.status === null) {
            setButtonProps({
                disabled: true,
                text: TEXT_BUTTON_GENERATE_REPORT,
                loading: false,
            });
        } else if (report?.status === ReportStatus.Uploaded) {
            setButtonProps({
                disabled: !isWithInsightReport && !isWithTranscript,
                text:
                    !isWithInsightReport && !isWithTranscript
                        ? 'Generate'
                        : isWithInsightReport
                          ? TEXT_BUTTON_GENERATE_REPORT
                          : TEXT_BUTTON_GENERATE_TRANSCRIPT,
                loading: false,
            });
        } else if (report?.status === ReportStatus.Finished) {
            setButtonProps({
                disabled: !isWithInsightReport && !isWithTranscript,
                text:
                    !isWithInsightReport && !isWithTranscript
                        ? 'Re-Generate'
                        : isWithInsightReport
                          ? TEXT_BUTTON_REGENERATE_REPORT
                          : TEXT_BUTTON_REGENERATE_TRANSCRIPT,
                loading: false,
            });
        } else if (report?.status === ReportStatus.Error) {
            console.log(ReportStatus.Error);
            setButtonProps({
                disabled: false,
                text: TEXT_BUTTON_REGENERATING_REPORT,
                loading: false,
            });
        } else {
            setButtonProps({
                disabled: true,
                text: TEXT_BUTTON_REGENERATING_REPORT,
                loading: true,
            });
        }
    }, [isWithInsightReport, isWithTranscript, report, isAdminView]);

    return { buttonProps, isAdminView };
};
