import { useEffect, useRef, useState } from 'react';

import MessageBubble from '../../../../MessageBubble';
import { Transcript } from 'src/@types/report';

import tabStyles from '../../ReportTabs.module.scss';

interface ReportTranscriptProps {
    transcripts: Transcript[];
    highlightedMessageId?: number;
}

export const ReportTranscript: React.FC<ReportTranscriptProps> = ({ transcripts, highlightedMessageId }) => {
    const highlightedMessageRef = useRef<HTMLDivElement>(null);
    const [localHighlightedId, setLocalHighlightedId] = useState<number | undefined>(highlightedMessageId);

    useEffect(() => {
        // Обновляем localHighlightedId, даже если highlightedMessageId стал undefined
        setLocalHighlightedId(highlightedMessageId);

        if (highlightedMessageId && highlightedMessageRef.current) {
            highlightedMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

            // Автоматически снимаем выделение через 2000 мс
            const timer = setTimeout(() => {
                setLocalHighlightedId(undefined);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [highlightedMessageId]);

    return (
        <div className={tabStyles.tabContainer}>
            <div className={tabStyles.contentWrapper}>
                <div className={tabStyles.gradientOverlayTop}></div>
                <div className={tabStyles.tabContent}>
                    {transcripts?.map(message => {
                        const isHighlighted = message.id === localHighlightedId;

                        return (
                            <div
                                key={message.id}
                                ref={message.id === highlightedMessageId ? highlightedMessageRef : null}
                                style={{
                                    backgroundColor: isHighlighted ? '#bbdaff55' : 'transparent',
                                    padding: isHighlighted ? '6px' : '6px',
                                    borderRadius: '22px',
                                    transition: 'all 0.5s ease',
                                    marginBottom: '4px',
                                }}
                            >
                                <MessageBubble
                                    key={message.id}
                                    isRespondent={message.is_respondent}
                                    knownName={message.known_name}
                                    text={message.text}
                                    line={message.id + 1}
                                />
                            </div>
                        );
                    })}
                    <div
                        style={{
                            height: '60px',
                            minHeight: '60px',
                            flexShrink: 0,
                            width: '100%',
                        }}
                    />
                </div>
                <div className={tabStyles.gradientOverlayBottom} style={{ height: '36px' }}></div>
            </div>
        </div>
    );
};
