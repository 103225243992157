import { message } from 'antd';

import { PATH } from '../../constants/paths';
import { firebaseAuth } from '../firebase/AseedFirebase';

export class ReferralService {
    static getRefCode(): string | undefined {
        return firebaseAuth.currentUser?.uid!.slice(-6).toUpperCase();
    }

    static generateReferralUrl(): string {
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        const refCode = this.getRefCode();
        return `${baseUrl}${PATH.REFERRAL}?code=${refCode}`;
    }

    static copyReferralLink(): Promise<void> {
        return navigator.clipboard
            .writeText(this.generateReferralUrl())
            .then(() => {
                message.success('Invite Link Copied');
            })
            .catch(err => {
                console.error('Unexpected error while copying ref code: ', err);
            });
    }
}
