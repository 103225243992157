import React, { useEffect, useRef, useState } from 'react';

import { DetailedReportResponse } from '../../../@types/jtbd';
import { useReportWidgetContext } from './ReportWidgetContext';

import styles from './ReportVersion.module.scss';

interface ReportVersionProps {
    report: DetailedReportResponse;
}

export const ReportVersion: React.FC<ReportVersionProps> = ({ report }) => {
    const [isVersionDropdownOpen, setIsVersionDropdownOpen] = useState<boolean>(false);
    const { selectedVersion, setSelectedVersion } = useReportWidgetContext();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleVersionSelect = (version: string) => {
        setSelectedVersion(version);
        setIsVersionDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsVersionDropdownOpen(false);
            }
        };

        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsVersionDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);

    if (report.last_version <= 0) return null;

    const versions = [];
    for (let i = report.last_version; i >= 1; i--) {
        versions.push(i === report.last_version ? 'Last' : i.toString());
    }

    const displayVersion = selectedVersion === 'Last' ? 'Last' : selectedVersion;

    return (
        <div className={styles.versionSelector} ref={dropdownRef}>
            <div className={styles.versionButton} onClick={() => setIsVersionDropdownOpen(!isVersionDropdownOpen)}>
                <span>Version: {displayVersion}</span>
                {/*<Icon name="hammerCircle" className={styles.versionIcon} />*/}
            </div>
            {isVersionDropdownOpen && (
                <div className={styles.versionDropdown}>
                    {versions.map((version, index) => (
                        <div
                            key={index}
                            className={`${styles.versionItem} ${version === selectedVersion ? styles.versionItemActive : ''}`}
                            onClick={() => handleVersionSelect(version)}
                        >
                            {version}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
