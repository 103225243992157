import React from 'react';

import { Icon, IconName } from './Icon';

import './Informer.scss';

interface InformerProps {
    text: React.ReactNode;
    showIcon?: boolean;
    iconName?: IconName;
    style?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    iconWidth?: number;
    iconHeight?: number;
    iconColor?: string;
}

const Informer: React.FC<InformerProps> = ({
    text,
    showIcon = true,
    iconName = 'aseedIcon',
    style,
    textStyle,
    iconWidth,
    iconHeight,
    iconColor,
}) => {
    // Если указан iconColor, используем его, иначе берём из textStyle или дефолтное значение.
    const effectiveIconColor = iconColor || (textStyle && textStyle.color) || '#4294E1';

    return (
        <div className="informer" style={style}>
            <div className="informer-content">
                {showIcon && (
                    <Icon
                        name={iconName}
                        width={iconWidth || 14}
                        height={iconHeight || 14}
                        fill={effectiveIconColor}
                        className="icon"
                    />
                )}
                <div className="text" style={textStyle}>
                    {text}
                </div>
            </div>
        </div>
    );
};

export default Informer;
